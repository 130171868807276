export enum ResponseCodes {
  SuccessLogin = 10,
  UserIsExists = 12,
  UserIsBlocked = 14,
  PasswordError = 16,
  Registered = 18,
  WrongEmailOrPassword = 20,
  UserIsNotExists = 22,
  Forbidden = 44,
  Success = 33,
  WrongCode = 24,
  EmailIsUsed = 26,
  NotRegistered = 28,
  NotDoneOnboarding = 50,

  // Withdraw, Deposit
  NotAvailableMoney = 102,
  WrongPinCode = 106,
  WrongOrderbookPriceRange = 110,

  ServerError = 500,

  // Promo code
  PromoCodeNotFound = 130,
  PromoCodeActivated = 132,
  PromoCodeExpired = 134,

  // Security & Anti-Spam
  DetectedBotActivity = 166,
}
