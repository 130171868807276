import React, { useCallback, useEffect } from "react";
import DealRow from "./deal";
import "./style.scss";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import { generalSocketStore } from "services/general.socket";
import { OperationType, OrderType } from "static/types";
import { useTranslation } from "react-i18next";

type PropsType = {
  assetId: number | undefined;
  noClearTrades?: boolean;
  realTimePrices?: { bid: string; ask: string } | undefined;
};

let dealsInterval: any = null;

export const DealsTable: React.FC<PropsType> = React.memo(
  ({ assetId, realTimePrices, noClearTrades = false }) => {
    const { t } = useTranslation("app");
    const { assetDeals, clearDeals, loadAssetDeals, cancelDeal } =
      useStore(marketStore);
    const { connected } = useStore(generalSocketStore);

    useEffect(() => {
      if (assetId) {
        if (!noClearTrades) clearDeals();
        clearInterval(dealsInterval);
        loadAssetDeals(assetId);
        if (!connected) {
          dealsInterval = setInterval(() => {
            if (assetId) {
              loadAssetDeals(assetId);
            }
          }, 6 * 1000);
        }
      } else {
        if (!noClearTrades) clearDeals();
        clearInterval(dealsInterval);
      }

      return () => {
        if (!noClearTrades) clearDeals();
        clearInterval(dealsInterval);
      };
    }, [assetId, connected]);

    const price = useCallback(
      (deal: any) => {
        const currSymbol = deal.currency.symbol;
        let dealPrice = `${deal.price}${currSymbol}`;
        if (deal.type === OrderType.Market) {
          if (realTimePrices) {
            const { bid, ask } = realTimePrices;
            if (deal.operation === OperationType.Buy) {
              dealPrice = ask || dealPrice;
            } else {
              dealPrice = bid || dealPrice;
            }
          }
        }
        return dealPrice;
      },
      [realTimePrices]
    );

    if (!assetDeals.length) return <></>;

    return (
      <table className="transaction-info-container">
        <tr className="transaction-info-title">
          <th>{t("ticker")}</th>
          {/* <th>{t("orderStatus")}</th> */}
          <th>{t("orderType")}</th>
          <th>{t("transactionType")}</th>
          <th>{t("price")}</th>
          <th>
            {t("quantity")} / <br /> {t("completed")}
          </th>
        </tr>
        {assetDeals.map((deal) => (
          <DealRow
            key={deal.id}
            ticker={deal.asset.symbol}
            orderType={t(deal.type)}
            status={deal.status}
            operationType={t(deal.operation)}
            price={price(deal)}
            quantity={String(deal.quantity)}
            quantity_completed={String(deal.quantity_complited)}
            onCancel={() => cancelDeal(deal.id)}
          />
        ))}
      </table>
    );
  },
  (prev, next) => {
    return prev.assetId === next.assetId;
  }
);
