import React, { useCallback, useState, useEffect } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { TabBtn } from "../components/tab-btn";
import { ActionBtn } from "../components/action-btn";
import IndicatorQ from "../../../assets/IndicatorQuest.svg";
import { Progress } from "rsuite";
import ReliabilityRating from "../components/reliability-rating-row";
import IndicatorDetails from "../components/indicators-details-row";
import DividentsDetails from "../components/dividents-details-row";
import HorizontalScroll from "../components/horizontal-scroll";
import { DealsTable, MarketChart } from "widgets/market";
import { sessionModel } from "entities/session";
import { CouponType, marketStore } from "entities/market";
import { useStore } from "zustand";
import { formatPrice, isBondMatured } from "helpers/functions";
import { AssetType, OperationType } from "static/types";
import { dashboardStore } from "entities/dashboard";
import ReviewDetails from "../components/review-details-row";
import { useTranslation } from "react-i18next";
import { formatMoney, getAvgValue } from "helpers/money";
import AssetIcon from "../components/asset-icon";
import { GetCountryByCode } from "static/countries";
import useCanAction from "hooks/useCanAction";
import useSaveHandler from "hooks/useSaveToFavorites";
import SavedToFavNotification from "../components/saved-to-favorites-notification";
import SaveToFavBtn from "../components/save-to-favorites-btn";
import moment from "moment";
import useExchangeTimeFrame from "hooks/useExchangeTimeFrame";
import ExchangeStatusBtn from "widgets/market/exchange-status-btn";
import ExchangeSource from "widgets/market/exchange-source";
import ExchangeStatusComp from "widgets/market/exchange-status";

export const BondDetailsPage = () => {
  const { t } = useTranslation("app");
  const [activeTab, setActiveTab] = useState("Bargaining");
  const { asset_id } = useParams();

  const action = useCanAction();

  const {
    isLoading,
    assetInfo,
    loadAssetInfo,
    loadAssetChart,
    addFavorites,
    removeFavorite,
  } = useStore(marketStore);

  const symbol = assetInfo?.asset?.symbol || "";
  const isMatured = isBondMatured(assetInfo?.bondInfo?.maturity_date || null);
  const exchange = useExchangeTimeFrame(assetInfo?.asset?.exchange);
  const [stockStatus, setStockStatus] = useState(false);

  const getAssetPrice = useCallback(
    (type: "buy" | "sell" | "avg") => {
      try {
        const asset = assetInfo?.asset;
        let price: any;
        if (type == "avg") {
          price = formatMoney(getAvgValue(asset!.ask, asset!.bid), 4);
        } else {
          price = formatMoney(type === "buy" ? asset!.ask : asset!.bid, 4);
        }
        return `${price}`; // ${assetInfo!.asset!.currency.symbol}
      } catch (error) {
        return "";
      }
    },
    [assetInfo]
  );

  const getAssetYield = useCallback(
    (type: "buy" | "sell" | "avg") => {
      try {
        const info = assetInfo?.bondInfo;
        if (!info) return "";
        const { bid_yield, ask_yield } = info;
        const yield_v = formatMoney(
          type === "avg"
            ? getAvgValue(bid_yield, ask_yield)
            : type === "buy"
            ? ask_yield
            : bid_yield
        );
        return `${yield_v}%`;
      } catch (error) {
        return "";
      }
    },
    [assetInfo]
  );

  const getPriceFormatted = useCallback(
    (type: "buy" | "sell" | "avg") => {
      return `${getAssetYield(type)} | ${getAssetPrice(type)}`;
    },
    [getAssetPrice, getAssetYield]
  );

  useEffect(() => {
    loadAssetInfo(asset_id);
    loadAssetChart(asset_id);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const { prevPathDeal } = useStore(dashboardStore);
  const [toPath, setToPath] = useState<any>(-1);

  useEffect(() => {
    if (prevPathDeal === true) {
      setToPath(PATH_LIST.market);
    } else {
      setToPath(-1);
    }
  }, [location]);

  const handleNavigate = () => {
    navigate(toPath);
  };
  const scroll = () => {
    const stockDetails = document.querySelector(".stock-details");
    if (stockDetails) {
      stockDetails.scrollTop = 0;
    }
  };

  const handleDeal = (type: number, operation: string) => {
    if (operation === "buy") {
      navigate(PATH_LIST.buy(asset_id!) + `?type=${type}`);
    } else {
      navigate(PATH_LIST.sell(asset_id!) + `?type=${type}`);
    }
  };
  const handleTabClick = (text: string) => {
    setActiveTab(text);
    scroll();
  };

  const calculateCouponDates = (
    issueDate: string,
    maturityDate: string,
    frequency: number
  ) => {
    try {
      const issue = new Date(issueDate);
      const maturity = new Date(maturityDate);
      const intervalMonths = 12 / frequency;
      const couponDates = [];

      let currentDate = new Date(issue);
      currentDate.setMonth(currentDate.getMonth() + intervalMonths);

      while (currentDate < maturity) {
        couponDates.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + intervalMonths);
      }

      // Add maturity date if not included
      if (
        couponDates[couponDates.length - 1].getTime() !== maturity.getTime()
      ) {
        couponDates.push(maturity);
      }

      return couponDates
        .map((date) => date.toISOString().split("T")[0])
        .reverse();
    } catch (error) {
      return [];
    }
  };

  const showDetailsPage = () => {
    return (
      <>
        {activeTab === "Bargaining" ? (
          <div className="bond-details-main bond-details-mainbonds-bargaining">
            {assetInfo?.bondInfo?.is_otc && (
              <div className="bargaining-alert">
                <span>{t("tradingInOTCMode")}</span>
              </div>
            )}
            <div className="bond-info-bargaining">
              <div>
                <AssetIcon
                  asset={{
                    type: assetInfo?.asset?.type || AssetType.Bond,
                    image: assetInfo?.asset?.image || "",
                    symbol: symbol!,
                  }}
                  className="asset-logo"
                />
                <div className="gain-percent">
                  <p className="price">{getAssetYield("avg")}</p>
                  <p className="price gains-percent">{getAssetPrice("avg")}</p>
                  {exchange && (
                    <ExchangeStatusBtn
                      onClick={() => setStockStatus(true)}
                      status={exchange.status}
                    />
                  )}
                </div>
                {/* <span>{getAssetYield()}</span>
                <p>{t("perAnnum")}</p> */}
                {/* <span>{assetInfo?.bondInfo?.nominal}</span>
                <p>{t("nominal")}</p> */}
              </div>
              {exchange && (
                <ExchangeSource
                  name={exchange.exchange_name}
                  code={exchange.exchange_code}
                  image={exchange.exchange_logo}
                />
              )}
            </div>
            {/* <div className="charts">
              <div
                className="chart-type-icon"
                onClick={() => {
                  setChart(!chart);
                }}
              >
                <img
                  src={chart == true ? LineGraph : JapaneseCandlesticks}
                  alt="chart-type-icon"
                />
              </div>
            </div> */}
            <MarketChart
            // onlyLineChart
            />
            <DealsTable assetId={assetInfo?.asset?.id || undefined} />
            {/* <div className="reliability-rating">
              <p className="rr-text">Reliability rating</p>
              <ReliabilityRating text="Very high" color={"#008069"} />
            </div> */}
            {stockStatus && exchange && (
              <ExchangeStatusComp
                tf={exchange}
                onClose={() => setStockStatus(false)}
              />
            )}
          </div>
        ) : activeTab === "Coupons" ? (
          <div className="bond-details-main">
            {/* //TODO translate before coupons */}
            {assetInfo?.bondInfo && (
              <>
                <div className="nearest-coupon">
                  <p className="title-NC">{t("nearest_coupon")}</p>
                  <div className="main-NC">
                    <div>
                      <p>{assetInfo!.bondInfo.next_coupon_date}</p>{" "}
                      <span>
                        {
                          +(
                            (assetInfo.bondInfo.coupon_rate /
                              100 /
                              assetInfo.bondInfo.coupon_frequency!) *
                            assetInfo.bondInfo.nominal
                          ).toFixed(4)
                        }{" "}
                        {assetInfo!.asset!.currency.symbol}
                      </span>
                    </div>
                    <div>
                      <p>
                        {assetInfo!.bondInfo.next_coupon_date
                          ? t("payment_date")
                          : ""}
                      </p>
                      <span>
                        {assetInfo.bondInfo.coupon_rate} % {t("perAnnum")}
                      </span>
                    </div>
                  </div>
                  <div className="nkd-progress">
                    <div className="nkd-progress-header">
                      <div className="nkd-progress-header-text">
                        <p>
                          {t("ACI")}{" "}
                          {/* {
                            +(
                              (assetInfo.bondInfo.ai /
                                ((assetInfo.bondInfo.coupon_rate /
                                  assetInfo.bondInfo.coupon_frequency! /
                                  100) *
                                  assetInfo.bondInfo.nominal)) *
                              (assetInfo.bondInfo.coupon_rate /
                                assetInfo.bondInfo.coupon_frequency!)
                            ).toFixed(4)
                          }
                          % */}
                        </p>
                        {/* <img src={IndicatorQ} alt="icon" /> */}
                      </div>
                      <span>
                        {assetInfo.bondInfo.ai}{" "}
                        {assetInfo!.asset!.currency.symbol}
                      </span>
                    </div>
                    <Progress.Line
                      className="progress-line-bond"
                      percent={
                        assetInfo.bondInfo.ai /
                        (+(
                          (assetInfo.bondInfo.coupon_rate /
                            100 /
                            assetInfo.bondInfo.coupon_frequency!) *
                          assetInfo.bondInfo.nominal
                        ).toFixed(4) /
                          100)
                      }
                      strokeColor="#F8009E"
                      showInfo={false}
                    />
                  </div>
                </div>
                <div className="indicators-title">
                  <p>{t("payment_calendar")}</p>
                </div>
                <div className="dividents-title">
                  <p>{t("payment_date")}</p>
                  <p>{t("coupon")}</p>
                </div>
                {calculateCouponDates(
                  assetInfo.bondInfo.settlement_date,
                  assetInfo.bondInfo.maturity_date,
                  assetInfo.bondInfo.coupon_frequency!
                ).map((v, i) => (
                  <DividentsDetails
                    key={i}
                    date={moment(v, "YYYY-MM-DD").format("DD.MM.YYYY")}
                    price={`${+(
                      (assetInfo.bondInfo.coupon_rate /
                        assetInfo.bondInfo.coupon_frequency! /
                        100) *
                      assetInfo.bondInfo.nominal
                    ).toFixed(4)} ${assetInfo!.asset!.currency.symbol}`}
                    year={""}
                    percent={""}
                    showYear={false}
                    annuallyYield={""}
                    yearString={""}
                  />
                ))}
              </>
            )}
          </div>
        ) : activeTab === "Indicators" ? (
          <div className="bond-details-main">
            <div className="not-found-coming-soon">
              <p>{t("notFound")}</p>
            </div>
          </div>
        ) : (
          <div className="bond-details-main bond-details-main-review">
            {/* <div className="review-title">
              <p>{t("description")}</p>
            </div>
            <span className="description-span">
              {getAssetInfo()?.description}
            </span>
            <div className="description-details">
              <ReviewDetails
                title="Industry"
                info={getAssetInfo()?.industry || ""}
              />
              <ReviewDetails
                title="Сapitalization"
                info={getAssetMarketCap()}
              />
            </div> */}
            <div className="review-title">
              <p>{t("info")}</p>
            </div>
            <div className="info-details">
              {/* <ReviewDetails title="Instrument type" info="Lorem Ipsum" /> */}
              {/* <ReviewDetails
                title="Ticker"
                info={assetInfo?.asset?.symbol || ""}
              /> */}
              <ReviewDetails
                title={t("country")}
                info={
                  GetCountryByCode(assetInfo?.bondInfo?.country || "")?.name ||
                  ""
                }
              />
              <ReviewDetails
                title="ISIN"
                info={assetInfo?.asset?.symbol || ""}
              />
              <ReviewDetails
                title={t("currency")}
                info={assetInfo?.asset?.currency?.code || ""}
              />
              <ReviewDetails
                title={t("maturityDate")}
                info={assetInfo?.bondInfo?.maturity_date || ""}
              />
              <ReviewDetails
                title={t("settlementDate")}
                info={assetInfo?.bondInfo?.settlement_date || ""}
              />
              <ReviewDetails
                title={t("nominal")}
                info={String(assetInfo?.bondInfo?.nominal)}
              />
              <ReviewDetails
                title={t("couponRate")}
                info={String(assetInfo?.bondInfo?.coupon_rate) + "%"}
              />
              <ReviewDetails
                title={t("couponType")}
                info={t(`couponType_${assetInfo?.bondInfo?.coupon_type}`)}
              />
              <ReviewDetails
                title={t("couponFrequency")}
                info={String(assetInfo?.bondInfo?.coupon_frequency || "")}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const { saved, showNotification, handleSave } = useSaveHandler(
    +asset_id!,
    addFavorites,
    removeFavorite
  );

  if (isLoading) {
    return (
      <div className={`stock-details stock-details${activeTab}`}>
        <div className="stock-details-header">
          <img
            className="go-back-portfolio"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          <div>
            <p>{symbol}</p>
          </div>
        </div>
        <div className="stock-details-main">
          <p className="assets-loading assets-loading-page">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`bond-details bond-details${activeTab}`}>
      {showNotification && <SavedToFavNotification symbol={symbol} />}

      <div className="bond-details-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <div>
          <p>{symbol}</p>
          <span>{assetInfo?.asset?.name || ""}</span>
        </div>

        {/* @ts-ignore */}
        <SaveToFavBtn onclick={() => handleSave(asset_id)} saved={saved} />
      </div>
      <HorizontalScroll className="tab-bar">
        <TabBtn
          text={t("trading")}
          active={activeTab === "Bargaining"}
          onClick={() => handleTabClick("Bargaining")}
        />
        {assetInfo?.bondInfo &&
          assetInfo.bondInfo.coupon_type === CouponType.Fixed && (
            <TabBtn
              text={t("coupons")}
              active={activeTab === "Coupons"}
              onClick={() => handleTabClick("Coupons")}
            />
          )}
        {/* <TabBtn
          text={t("indicators")}
          active={activeTab === "Indicators"}
          onClick={() => handleTabClick("Indicators")}
        /> */}
        <TabBtn
          text={t("review")}
          active={activeTab === "Review"}
          onClick={() => handleTabClick("Review")}
        />
      </HorizontalScroll>
      {showDetailsPage()}
      <div className="action-btns-wrapper">
        <ActionBtn
          onClick={() =>
            action(() => handleDeal(assetInfo!.asset!.type, OperationType.Sell))
          }
          action={t("sell")}
          price={getPriceFormatted("sell")}
          disabled={isMatured}
        />
        <ActionBtn
          onClick={() =>
            action(() => handleDeal(assetInfo!.asset!.type, OperationType.Buy))
          }
          action={t("buy")}
          price={getPriceFormatted("buy")}
          type="dark"
          disabled={isMatured}
        />
      </div>
    </div>
  );
};
