import React, { FC } from "react";
import "./style.scss";
import ChevronDown from "../../../../assets/ChevronDown.svg";
import { useTranslation } from "react-i18next";

type PropsType = {
  onClick: () => void;
  image: any;
  currency?: any;
  bank?: string;
  custom?: string;
  customText?: string;
};

const Currency: FC<PropsType> = ({
  onClick,
  image,
  currency,
  bank,
  custom,
  customText,
}) => {
  const { t } = useTranslation("app");

  return (
    <div onClick={onClick} className="currency-btn-container">
      {currency ? (
        <>
          <span>{t("currency")}</span>
          <div className="currency-btn">
            <div>
              <img src={image} alt="Currency" />
              <p>{currency}</p>
            </div>
            <img src={ChevronDown} alt="ChevronDown" />
          </div>
        </>
      ) : bank ? (
        <>
          <span>{t("recipientBank")}</span>
          <div className="currency-btn">
            <div>
              <img src={image} alt="Bank" />
              <p>{bank}</p>
            </div>
            <img src={ChevronDown} alt="ChevronDown" />
          </div>
        </>
      ) : (
        <>
          <span>{customText}</span>
          <div className="currency-btn">
            <div>
              <img src={image} alt="" />
              <p>{custom}</p>
            </div>
            <img src={ChevronDown} alt="ChevronDown" />
          </div>
        </>
      )}
    </div>
  );
};

export default Currency;
