import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper/modules";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import { accountStore } from "entities/account";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import Modal from "widgets/auth/modal";
import { StrategyChart } from "widgets/strategy/chart";
import { DealsTable, MarketChart } from "widgets/market";
import {
  formatPrice,
  getMLValue,
  getPreviewIccContractUrl,
  getStoryImgUrl,
  getStrategyImgUrl,
  navigateToAsset,
} from "helpers/functions";
import { AssetType, IccContractType, OperationType } from "static/types";
import useCanAction from "hooks/useCanAction";
import { ActionBtn } from "../components/action-btn";
import HorizontalScroll from "../components/horizontal-scroll";
import { TabBtn } from "../components/tab-btn";
import IndicatorDetails from "../components/indicators-details-row";
import ReviewDetails from "../components/review-details-row";
import ReportItem from "../components/report-item";
import Loading from "widgets/loading";

const RISK_TYPES = ["Low Risk", "Average Risk", "High Risk"] as const;

export const StrategyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { asset_id } = useParams();
  const { t, i18n } = useTranslation("app");
  const { t: err } = useTranslation("errors");
  const action = useCanAction();
  const { isLoading, strategy, getStrategy, loadAssetDeals, assetDeals } =
    useStore(marketStore);
  const { getDocuments, signIccContract } = useStore(accountStore);

  //! Extracted Strategy Properties
  // const asset = strategy?.asset;
  // const { symbol = "", currency, data, description = "" } = asset || {};
  // const {
  //   risk,
  //   chart_symbol: chartSymbol,
  //   annual_yield: annualYield,
  // } = data || {};
  // const { code: currencyCode, symbol: currencySymbol } = currency || {};
  // const accountExists = strategy?.account?.quantity > 0;
  // const enableTrading = strategy?.enableTrading ?? true;
  //!

  // const asset = strategy?.asset;
  const symbol = strategy?.asset?.symbol || "";
  const risk = strategy?.asset?.data?.risk;
  const chartSymbol = strategy?.asset?.data?.chart_symbol;
  const annualYield = strategy?.asset?.data?.annual_yield;
  const currency = strategy?.asset?.currency?.code;
  const currencySymbol = strategy?.asset?.currency?.symbol;

  const account = strategy?.account;
  const accountExists = account && account.quantity > 0;

  const desc = strategy?.description || "";
  const customInfo = strategy?.customInfo || null;

  const enableTrading =
    strategy && "enableTrading" in strategy ? strategy.enableTrading : true;
  const capitalProtected = strategy?.capital_protected || "";

  // Component State
  const [activeBtn, setActiveBtn] = useState("Graph");
  const [activeTab, setActiveTab] = useState(
    location.state?.tab ||
      (strategy?.customInfo?.indicators ? "indicators" : "review")
  );
  const [docs, setDocs] = useState<any[]>([]);
  const [modal, setModal] = useState(false);
  const [disable, setDisabled] = useState(false);
  const [reportId, setReportId] = useState<number>();

  const handleNavigate = () => {
    navigate(
      location.state?.from === PATH_LIST.market
        ? PATH_LIST.market
        : PATH_LIST.whatToBuy
    );
  };

  const handleTabClick = (text: string) => {
    setActiveTab(text);
    scroll();
  };

  const scroll = () => {
    const stockDetails = document.querySelector(".stock-details");
    if (stockDetails) {
      stockDetails.scrollTop = 0;
    }
  };

  const onSignContract = async (pinCode: string) => {
    setDisabled(true);
    try {
      await signIccContract(pinCode, reportId!, err, () => {
        window.location.reload();
      });
    } catch (error) {
      toast.error("Server Error");
    } finally {
      setModal(false);
      setDisabled(false);
    }
  };

  useEffect(() => {
    getStrategy(asset_id!).then(() => {
      loadAssetDeals(+asset_id!);
    });
  }, []);

  useEffect(() => {
    if (symbol) {
      getDocuments(symbol).then((data: any) => {
        setDocs(data.data);
      });
    } else {
      setDocs([]);
    }
  }, [symbol]);

  useEffect(() => {
    if (accountExists || assetDeals.length > 0) {
      setActiveTab("trading");
    } else {
      setActiveTab(strategy?.customInfo?.indicators ? "indicators" : "review");
    }
  }, [strategy, accountExists, assetDeals.length]);

  const getTotalAmount = () => {
    try {
      return formatPrice(+(account!.quantity * account!.price).toFixed(2));
    } catch (error) {
      return "";
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="strategy-page">
      <div
        className={`strategy-header-container strategy-header-container-${risk}`}
        style={{
          background: customInfo
            ? `linear-gradient(256.2deg, ${customInfo.info.background.to} -7.74%, ${customInfo.info.background.from} 100%)`
            : "",
          ...(customInfo?.info?.hide_header_cards && {
            minHeight: "72px",
            height: "72px",
          }),
        }}
      >
        <div className="strategy-header">
          <img
            className="go-back-strategy"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          <p>{strategy?.asset?.name}</p>
        </div>
        {customInfo && customInfo?.info?.hide_header_cards ? (
          <></>
        ) : (
          <div className="strategy-header-info">
            <div className="sh-info-box">
              {customInfo && customInfo.info?.yield ? (
                <>
                  <p>{getMLValue(customInfo.info.yield.p, i18n.language)}</p>
                  <span>
                    {getMLValue(customInfo.info.yield.span, i18n.language)}
                  </span>
                </>
              ) : (
                <>
                  <p>≈ {annualYield}%</p>
                  <span>{t("annualReturn")}</span>
                </>
              )}
            </div>
            <div className="sh-info-box">
              <p>
                {strategy?.asset?.data?.minimal_invest} {currencySymbol}
              </p>
              <span>{t("initialInvestmentAmount")}</span>
            </div>
            <div className="sh-info-box">
              <span>{t(RISK_TYPES[risk ? risk - 1 : 0])}</span>
            </div>
          </div>
        )}
      </div>

      {customInfo && (
        <HorizontalScroll className="tab-bar tab-bar-cat">
          {[
            {
              key: "trading",
              condition: accountExists || assetDeals.length > 0,
            },
            { key: "indicators", condition: customInfo.indicators },
            { key: "review", condition: true },
            {
              key: "capitalProtection",
              condition: customInfo.capital_protection,
            },
            { key: "disclaimer", condition: customInfo.disclaimer },
          ]
            .filter(({ condition }) => condition)
            .map(({ key }) => (
              <TabBtn
                key={key}
                text={t(key)}
                active={activeTab === key}
                onClick={() => handleTabClick(key)}
              />
            ))}
        </HorizontalScroll>
      )}

      <div
        className="strategy-main"
        style={{ paddingTop: customInfo ? "0px" : "24px" }}
      >
        {!customInfo && (
          <div className="strategy-info-section">
            <button
              className={activeBtn == "Graph" ? "active-btn" : ""}
              onClick={() => setActiveBtn("Graph")}
            >
              {t("graph")}
            </button>
            <button
              className={activeBtn == "Description" ? "active-btn" : ""}
              onClick={() => setActiveBtn("Description")}
            >
              {t("description")}
            </button>
          </div>
        )}

        {!customInfo ? (
          activeBtn == "Graph" ? (
            <div className="sm-info-container">
              <StrategyChart
                chart={strategy?.chart}
                title={chartSymbol ? `${chartSymbol} stock chart` : undefined}
              />
              <DealsTable assetId={strategy?.asset?.id || undefined} />
            </div>
          ) : (
            <div
              className="sm-info-container sm-info-container-description"
              dangerouslySetInnerHTML={{ __html: desc }}
            ></div>
          )
        ) : activeTab === "trading" ? (
          <div className="sm-tab-name sm-tab-trading">
            <div>
              <div
                className={
                  capitalProtected
                    ? "current-investment-amount-strategy-protected"
                    : "current-investment-amount-strategy"
                }
              >
                <div className="current-investment-amount-wrapper">
                  <label>{t("currentInvestmentAmount")}</label>
                  <p>
                    {accountExists ? getTotalAmount() : 0} {currencySymbol}
                  </p>
                </div>
                {capitalProtected && (
                  <div className="protection-amount-wrapper">
                    <label style={{ marginTop: "12px" }}>
                      Подключена{" "}
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("capitalProtection");
                        }}
                      >
                        защита капитала.
                      </span>
                      <br />
                      Уплаченная премия:{" "}
                      <b>
                        {formatPrice(+capitalProtected.toFixed(2))}{" "}
                        {currencySymbol}
                      </b>
                    </label>
                  </div>
                )}
              </div>

              {docs.length ? (
                <div>
                  <p className="brokerage-section-name">{t("agreements")}</p>
                  <div className="report-date-item-wrapper">
                    {docs.map((report, index) =>
                      report.signed ? (
                        <ReportItem
                          key={index}
                          file={report.file}
                          path={"account/documents"}
                          name={`${t("agreementFor")} ${report.date}`}
                        />
                      ) : (
                        <ReportItem
                          key={index}
                          onClick={() => {
                            window.open(
                              getPreviewIccContractUrl(
                                report.user_id,
                                report.user_type,
                                report?.amount || 0,
                                symbol
                              ),
                              "_blank"
                            );
                          }}
                          name={`${t("agreementFor")} ${report.date}`}
                          needSign
                          onSign={() => {
                            setReportId(report.id);
                            setModal(true);
                          }}
                        />
                      )
                    )}
                  </div>
                </div>
              ) : (
                <></>
                // <div className="not-found-coming-soon">
                //   <p>{t("notFound")}</p>
                // </div>
              )}
            </div>

            <DealsTable
              assetId={strategy?.asset?.id || undefined}
              noClearTrades={true}
            />
          </div>
        ) : activeTab == "indicators" ? (
          <div className="sm-tab-name">
            {/* {customInfo.indicators.list &&
              //@ts-ignore
              customInfo.indicators.list.map((param, index) => (
                <div key={index} className="indicators-title review-title">
                  <p>{getMLValue(param.label, i18n.language)}</p>
                  <span className="description-span">
                    {getMLValue(param.value, i18n.language)}
                  </span>
                  {param?.list && (
                    <>
                      <p className="indicators-list-label">
                        {getMLValue(param.list.label, i18n.language)}
                      </p>
                      <ul>
                        {(
                          (getMLValue(param.list.value, i18n.language) ||
                            []) as any
                        ).map((item: string) => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))} */}
            {customInfo?.indicators?.data &&
              // @ts-ignore
              customInfo?.indicators?.data.map((item, index) => {
                if (item.type === "text") {
                  return (
                    <div key={index} className="review-title">
                      <p>{getMLValue(item.label, i18n.language)}</p>
                      <span className="description-span">
                        {getMLValue(item.value, i18n.language)}
                      </span>
                    </div>
                  );
                }
                if (item.type === "params") {
                  return (
                    <div key={index}>
                      <div className="review-title">
                        {/* <p>{t("keyParameters")}</p> */}
                        {item.label && (
                          <p>{getMLValue(item.label, i18n.language)}</p>
                        )}
                      </div>

                      {item.value.map((param: any, paramIndex: number) => (
                        <ReviewDetails
                          key={paramIndex}
                          title={getMLValue(param.label, i18n.language)}
                          info={getMLValue(param.value, i18n.language)}
                          type={param.type == "array" ? param.value : null}
                        />
                      ))}
                    </div>
                  );
                }
                if (item.type === "image") {
                  return (
                    <div key={index} className="review-title">
                      {item.description && (
                        <span>
                          {getMLValue(item.description, i18n.language)}
                        </span>
                      )}
                      <p>{getMLValue(item.label, i18n.language)}</p>
                      <img
                        style={{
                          width: "100%",
                          borderRadius: "8px",
                        }}
                        src={getStrategyImgUrl(
                          symbol,
                          getMLValue(item.value, i18n.language)
                        )}
                        alt="Image"
                      />
                    </div>
                  );
                }
                if (item.type === "list") {
                  return (
                    <div key={index} className="review-title">
                      <p className="review-list-label ">
                        {getMLValue(item.label, i18n.language)}
                      </p>
                      <ul>
                        {item.value.map(
                          (listItem: string, listIndex: number) => (
                            <li key={listIndex}>{listItem}</li>
                          )
                        )}
                      </ul>
                    </div>
                  );
                }
                if (item.type === "companies") {
                  return (
                    <div key={index} className="review-title">
                      <p>{getMLValue(item.label, i18n.language)}</p>
                      {item.value.map((company: any, companyIndex: number) => (
                        <div
                          key={companyIndex}
                          className="review-title-content"
                        >
                          <p>{getMLValue(company.name, i18n.language)}</p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getMLValue(
                                company.description,
                                i18n.language
                              ),
                            }}
                          />
                          {company?.images?.map((image: any, index: number) => (
                            <img
                              key={index}
                              src={getStrategyImgUrl(
                                symbol,
                                getMLValue(image, i18n.language)
                              )}
                              alt={getMLValue(image, i18n.language)}
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              })}

            {customInfo?.indicators?.companies && (
              <Swiper
                style={{ width: "100%", padding: "16px 24px" }}
                wrapperClass="strategy-indicator-box"
                spaceBetween={12}
                slidesPerView={1.1}
                scrollbar={{ draggable: true }}
              >
                {/* @ts-ignore */}
                {customInfo.indicators.companies.map((param, index) => (
                  <SwiperSlide key={index}>
                    <div className="indicator-box">
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "6px",
                        }}
                      >
                        <img
                          style={{
                            width: "36px",
                            height: "36px",
                            borderRadius: "100%",
                          }}
                          src={getStrategyImgUrl(
                            strategy?.asset?.symbol || "",
                            param.logo
                          )}
                          alt={param.name}
                        />
                        <p>{param.name}</p>
                      </div>
                      {/* @ts-ignore */}
                      {param.data.map((param, index) => (
                        <div key={index} className="indicator-box-row">
                          <span
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {getMLValue(param.label, i18n.language)}
                          </span>
                          <span
                            style={{
                              fontWeight: 600,
                              color: " #131722",
                              textAlign: "right",
                            }}
                          >
                            {getMLValue(param.value, i18n.language)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}

            <div style={{ marginTop: "12px" }}>
              {customInfo?.indicators?.references?.map(
                (param: any, index: any) => (
                  <p
                    style={{ textAlign: "left", fontSize: "12px" }}
                    key={index}
                  >
                    {getMLValue(param.value)}
                  </p>
                )
              )}
            </div>
          </div>
        ) : activeTab == "review" ? (
          <div className="sm-tab-name">
            {/* @ts-ignore */}
            {customInfo.info.data.map((item, index) => {
              if (item.type == "text") {
                return (
                  <div key={index} className="review-title">
                    {item.label && (
                      <p>{getMLValue(item.label, i18n.language)}</p>
                    )}
                    <span
                      className="description-span"
                      dangerouslySetInnerHTML={{
                        __html: getMLValue(item.value, i18n.language),
                      }}
                    ></span>
                  </div>
                );
              }
              if (item.type === "params") {
                return (
                  <div key={index}>
                    <div className="review-title">
                      {/* <p>{t("keyParameters")}</p> */}
                      {item.label && (
                        <p>{getMLValue(item.label, i18n.language)}</p>
                      )}
                    </div>

                    {item.value.map((param: any, paramIndex: number) => (
                      <ReviewDetails
                        key={paramIndex}
                        title={getMLValue(param.label, i18n.language)}
                        info={getMLValue(param.value, i18n.language)}
                        type={param.type == "array" ? param.value : null}
                      />
                    ))}
                  </div>
                );
              }
              if (item.type === "image") {
                return (
                  <div key={index} className="review-title">
                    {item.description && (
                      <span>{getMLValue(item.description, i18n.language)}</span>
                    )}
                    {item.label && (
                      <p>{getMLValue(item.label, i18n.language)}</p>
                    )}

                    <img
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                      }}
                      src={getStrategyImgUrl(
                        symbol,
                        getMLValue(item.value, i18n.language)
                      )}
                      alt="Image"
                    />
                  </div>
                );
              }
              if (item.type === "list") {
                return (
                  <div key={index} className="review-title">
                    {item.label && (
                      <p className="review-list-label">
                        {getMLValue(item.label, i18n.language)}
                      </p>
                    )}
                    {item.text && (
                      <span className="review-list-info">
                        {getMLValue(item.text, i18n.language)}
                      </span>
                    )}
                    <ul>
                      {item.value.map((listItem: string, listIndex: number) => (
                        <li key={listIndex}>{listItem}</li>
                      ))}
                    </ul>
                  </div>
                );
              }
              if (item.type === "companies") {
                return (
                  <div key={index} className="review-title">
                    <p>{getMLValue(item.label, i18n.language)}</p>
                    {item.value.map((company: any, companyIndex: number) => (
                      <div key={companyIndex} className="review-title-content">
                        <p>{getMLValue(company.name, i18n.language)}</p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getMLValue(
                              company.description,
                              i18n.language
                            ),
                          }}
                        />
                        {company?.images?.map((image: any, index: number) => (
                          <img
                            key={index}
                            src={getStrategyImgUrl(
                              symbol,
                              getMLValue(image, i18n.language)
                            )}
                            alt={getMLValue(image, i18n.language)}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : activeTab == "capitalProtection" ? (
          <div className="sm-tab-name">
            {/* @ts-ignore */}
            {customInfo?.capital_protection?.data?.map((item, index) => {
              if (item.type == "text") {
                return (
                  <div key={index} className="review-title">
                    {item.label && (
                      <p>{getMLValue(item.label, i18n.language)}</p>
                    )}
                    <span
                      className="description-span"
                      dangerouslySetInnerHTML={{
                        __html: getMLValue(item.value, i18n.language),
                      }}
                    ></span>
                  </div>
                );
              }
              if (item.type === "image") {
                return (
                  <div key={index} className="review-title">
                    {item.description && (
                      <span>{getMLValue(item.description, i18n.language)}</span>
                    )}
                    {item.label && (
                      <p>{getMLValue(item.label, i18n.language)}</p>
                    )}

                    <img
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                      }}
                      src={getStrategyImgUrl(
                        symbol,
                        getMLValue(item.value, i18n.language)
                      )}
                      alt="Image"
                    />
                  </div>
                );
              }
              if (item.type === "list") {
                return (
                  <div key={index} className="review-title">
                    {item.label && (
                      <p className="review-list-label">
                        {getMLValue(item.label, i18n.language)}
                      </p>
                    )}
                    {item.text && (
                      <span className="review-list-info">
                        {getMLValue(item.text, i18n.language)}
                      </span>
                    )}
                    <ul>
                      {item.value.map((listItem: string, listIndex: number) => (
                        <li key={listIndex}>{listItem}</li>
                      ))}
                    </ul>
                  </div>
                );
              }
            })}
          </div>
        ) : (
          <div className="sm-tab-name">
            <div className="review-title">
              <p>{t("disclaimer")}</p>
            </div>

            {customInfo.disclaimer.text && (
              <div className="indicators-title review-title">
                <span className="description-span">
                  {getMLValue(customInfo.disclaimer.text, i18n.language)}
                </span>
              </div>
            )}
            {customInfo.disclaimer.text && (
              <div className="indicators-title review-title">
                <span className="description-span">
                  {getMLValue(customInfo.disclaimer.text, i18n.language)}
                </span>
              </div>
            )}

            <div className="review-title">
              <p>{t("references")}</p>
            </div>

            {customInfo.disclaimer.references &&
              //@ts-ignore
              customInfo.disclaimer.references.map((param, index) => (
                <div key={index} className="indicators-info">
                  <p style={{ fontSize: "14px" }}>
                    {getMLValue(param.title, i18n.language)}
                  </p>
                  <span>
                    {t("sources")} {/* @ts-ignore */}
                    {param.list.map((item, index) => (
                      <span
                        style={{ fontWeight: 600, fontSize: "13px" }}
                        key={index}
                      >
                        {getMLValue(item.label, i18n.language)}
                        {index < param.list.length - 1 && ", "}
                      </span>
                    ))}
                  </span>
                  <ul>
                    {/* @ts-ignore */}
                    {param.list.map((item, idx) => (
                      <li key={idx}>
                        <a
                          href={item.link[i18n.language]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getMLValue(item.link, i18n.language)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        )}
      </div>

      {accountExists ? (
        <div className="strategy-action-btns-wrapper">
          <ActionBtn
            action="Increase"
            onClick={() =>
              action(() =>
                navigateToAsset(
                  AssetType.StructuredProduct,
                  asset_id,
                  navigate,
                  OperationType.Buy
                )
              )
            }
            type="dark"
            disabled={!enableTrading}
          />
          <ActionBtn
            action="Decrease"
            onClick={() =>
              action(() =>
                navigateToAsset(
                  AssetType.StructuredProduct,
                  asset_id,
                  navigate,
                  OperationType.Sell
                )
              )
            }
            disabled={!enableTrading}
          />
        </div>
      ) : (
        <div className="btn-wrapper deal-btn-wrapper">
          <Button
            type="submit"
            variant="dark"
            className="customBtn deal-btn"
            disabled={!enableTrading}
            onClick={() =>
              action(() =>
                navigateToAsset(
                  AssetType.StructuredProduct,
                  asset_id,
                  navigate,
                  OperationType.Buy
                )
              )
            }
          >
            {t("invest")}
          </Button>
        </div>
      )}

      {modal && (
        <Modal
          customModal="modalLogOut"
          onClick={onSignContract}
          onHide={() => {
            setModal(false);
            setDisabled(false);
          }}
          title={t("signContract")}
          text={t("enterPin")}
          btnText={t("sign")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal={true}
          isLoading={isLoading || disable}
          dealError={false}
        />
      )}
    </div>
  );
};
