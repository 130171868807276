import React, { useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import DatePicker from "../components/date-picker";
import RadioCard from "pages/onboarding/components/radio-card";
import Button from "shared/ui/button";
import moment from "moment";
import { toast } from "react-toastify";
import ReportItem from "../components/report-item";
import { useStore } from "zustand";
import { accountStore } from "entities/account";
import { sircapApi } from "shared/api/sircap";
import { useTranslation } from "react-i18next";

export const BrokerageReportPage: React.FC = () => {
  const { t } = useTranslation("app");
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { isLoading, reports, getReports, generateReport } =
    useStore(accountStore);

  const [isLoad, setIsLoad] = useState(false);

  const handleNavigate = () => {
    navigate(PATH_LIST.account);
  };

  useEffect(() => {
    setIsLoad(true);
    getReports().finally(() => setIsLoad(false));
  }, []);

  const onRequest = () => {
    if (moment(endDate).diff(moment(startDate), "day") < 0) {
      toast.error("End date can't be less than start date!");
    }
    const from = moment(startDate).format("DD.MM.YYYY");
    const to = moment(endDate).format("DD.MM.YYYY");
    generateReport({ from, to })
      .then((fileName) => {
        if (fileName) {
          toast.success("Success");
          window.open(`${sircapApi.baseUrl}/reports/${fileName}`, "_blank");
        } else {
          toast.error("Server Error");
        }
      })
      .catch(() => {
        toast.error("Server Error");
      });
  };

  return (
    <div className="brokerage-report">
      <div className="brokerage-report-header">
        <img
          className="go-back-brokerage"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("brokerageReports")}</p>
      </div>
      <div className="brokerage-report-main">
        <p className="brokerage-section-name">{t("selectTimeRange")}</p>
        <div className="report-date-wrapper">
          <DatePicker
            deadline={t("start")}
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
            report={true}
          />
          <DatePicker
            deadline={t("end")}
            selected={endDate}
            onChange={(date: any) => setEndDate(date)}
            report={true}
          />
        </div>

        <p className="brokerage-section-name">{t("generatedReports")}</p>
        {reports.length ? (
          <div className="report-date-item-wrapper">
            {reports.map((report, index) => (
              <ReportItem
                key={index}
                file={report.filename}
                path={`reports/${report.user_identificator}`}
                name={`${t("reportFor")} ${report.from_date} - ${
                  report.to_date
                }`}
              />
            ))}
          </div>
        ) : isLoad ? (
          <p className="assets-loading">{t("loading")}</p>
        ) : (
          <div className="not-found-coming-soon">
            <p>{t("notFound")}</p>
          </div>
        )}

        {/* <p className="brokerage-section-name">Select language</p>
        <div className="select-brokerage-language">
          <RadioCard
            customClass="brokerage-language"
            checked
            onClick={console.log("dd")}
            text="Armenian"
            htmlForId="radio"
          />
          <RadioCard
            customClass="brokerage-language"
            onClick={console.log("dd")}
            text="English"
            htmlForId="radio1"
          />
          <RadioCard
            customClass="brokerage-language"
            onClick={console.log("dd")}
            text="Russian"
            htmlForId="radio2"
          />
        </div> */}

        <div className="btn-wrapper">
          <Button
            onClick={onRequest}
            disabled={isLoading}
            type="submit"
            variant="dark"
            className="customBtn"
          >
            {t("request")}
          </Button>
        </div>
      </div>
    </div>
  );
};
