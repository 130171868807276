import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/main.scss";
import App from "app";
import "./app/libs/i18n";
import { registerServiceWorker } from "serviceWorker";
// import { hotjar } from "react-hotjar";
import TagManager from "react-gtm-module";

// const hjid = Number(process.env.REACT_APP_HJ_ID);
// const hjsv = Number(process.env.REACT_APP_HJ_SV);

// if (hjid && hjsv) {
//   console.log("Hotjar init!");
//   hotjar.initialize(hjid, hjsv);
// }

const tmID = String(process.env.REACT_APP_GOOGLE_TAG_MANAGER);

if (tmID) {
  const tagManagerArgs = {
    gtmId: tmID,
  };

  TagManager.initialize(tagManagerArgs);
}

//! reCAPTCHA script
// const loadRecaptchaScript = () => {
//   const script = document.createElement("script");
//   script.src =
//     "https://www.google.com/recaptcha/api.js?render=6LcRa8sqAAAAAHBesyMN89hZ9XnJce_GZWsBV165";
//   script.async = true;
//   script.defer = true;
//   document.head.appendChild(script);
// };

// loadRecaptchaScript();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

registerServiceWorker();
