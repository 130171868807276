import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { PATH_LIST } from "shared/lib/react-router";
import { sessionModel } from "entities/session";
import { accountStore } from "entities/account";
import { getMLValue } from "helpers/functions";
import Modal from "widgets/auth/modal";
import Button from "shared/ui/button";
import { PersonalInfoEditCard } from "../components/personal-info-edit-card";
import { AssignmentsFormField } from "../components/assignment-form-fields";
import GoBack from "../../../assets/GoBackApp.svg";
import IconUp from "../../../assets/WhiteVector.svg";
import HistoryIcon from "../../../assets/ClockDark.svg";
import AssignmentsHistory from "../../../assets/AssignmentsHistory.svg";
import "./style.scss";
import Loading from "widgets/loading";

export const AssignmentPage = () => {
  const { t, i18n } = useTranslation("app");
  const navigate = useNavigate();
  const location = useLocation();
  const [assignmentType, setAssignmentType] = useState(
    location.state?.assignmentType || false
  );
  const [dto, setDto] = useState<Record<string, any>>({});
  const { isLoading, assignment, getAssignment, submitAssignment } =
    useStore(accountStore);

  const handleNavigate = () => {
    navigate(PATH_LIST.assignments);
  };

  useEffect(() => {
    getAssignment(assignmentType);
  }, []);

  const isFormValid = () => {
    if (!assignment) return false;
    return assignment.form.every(
      (field) => field.isOptional || dto[field.field]
    );
  };

  const onSubmit = async () => {
    const data = await submitAssignment({ type: assignmentType, dto });

    if (!data || !data.id) {
      console.error("Submission failed or ID missing:", data);
      return;
    }

    navigate(PATH_LIST.assignmentSignContract(assignmentType, data.id));
  };

  if (isLoading || !assignment) {
    return <Loading />;
  }

  return (
    <div className="assignment-page">
      <div className="assignment-page-header">
        <img
          className="go-back-assignment"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{assignment && getMLValue(assignment.title, i18n.language)}</p>
        <img
          className="history-assignment"
          onClick={() => {
            navigate(PATH_LIST.assignmentsIndividualHistory(assignmentType));
          }}
          src={HistoryIcon}
          alt="history"
        />
      </div>

      <div className="assignment-page-main">
        {assignment &&
          assignment.form.map((item, index) => {
            if (item.type === "asset") {
              return (
                <AssignmentsFormField
                  key={index}
                  type="asset"
                  label={item.label}
                  assetTypes={item.assetTypes}
                  isOptional={item.isOptional}
                  onChange={(value, valid) => {
                    setDto((prev) => ({
                      ...prev,
                      [item.field]: valid ? value : "",
                    }));
                  }}
                />
              );
            }
            if (item.type === "select") {
              return (
                <AssignmentsFormField
                  key={index}
                  type="select"
                  label={item.label}
                  selectOptions={item.selectOptions}
                  isOptional={item.isOptional}
                  onChange={(value) => {
                    setDto((prev) => ({ ...prev, [item.field]: value }));
                  }}
                />
              );
            }
            if (item.type === "number") {
              return (
                <AssignmentsFormField
                  key={index}
                  type="number"
                  label={item.label}
                  isOptional={item.isOptional}
                  onChange={(value) => {
                    setDto((prev) => ({ ...prev, [item.field]: value }));
                  }}
                />
              );
            }
            if (item.type === "string") {
              return (
                <AssignmentsFormField
                  key={index}
                  type="string"
                  label={item.label}
                  isOptional={item.isOptional}
                  onChange={(value) => {
                    setDto((prev) => ({ ...prev, [item.field]: value }));
                  }}
                />
              );
            }
            return null;
          })}
      </div>

      <div className="btn-wrapper">
        <Button
          disabled={!isFormValid()}
          type="submit"
          variant={"dark"}
          onClick={onSubmit}
        >
          {t("confirm")}
        </Button>
      </div>
    </div>
  );
};
