import { accountStore } from "entities/account";
import { RegisterStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sircapApi } from "shared/api/sircap";
import { PATH_LIST, RegisterStepPages } from "shared/lib/react-router";
import Loading from "widgets/loading";
import { useStore } from "zustand";

export function AppGuard(props: PropsWithChildren) {
  const { addUser, accessToken, refreshToken } = useStore(
    sessionModel.sessionStore
  );
  const { synckyc, synckyb } = useStore(accountStore);
  const { syncUser } = useStore(onboardModel.onboardStore);
  const navigate = useNavigate();
  const location = useLocation();
  const { t: err } = useTranslation("errors");
  const { t: notif } = useTranslation("toast");

  const [isLogined, setIsLogined] = useState<boolean | null>(null);

  const auth = async () => {
    try {
      sircapApi.setSecurityData(refreshToken);
      const resp = await sircapApi.auth.token();
      if (resp.error) setIsLogined(false);
      else {
        const {
          user,
          accessToken,
          refreshToken,
          entity,
          kyc,
          kyb,
          questionnaireStatus,
        } = resp.data.data;
        // add check if user.status is active
        syncUser(user, entity);
        synckyc(kyc || null);
        synckyb(kyb || null);
        accountStore.setState({ questionnaireStatus });
        addUser(user, accessToken, refreshToken, entity);

        if (user.status !== 1) {
          navigate(PATH_LIST.registration);
        } else {
          // toast.success("Success login");
          // navigate(PATH_LIST.home);
          setIsLogined(true);
        }
      }
    } catch (error) {
      setIsLogined(false);
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    auth();
  }, []);

  useEffect(() => {
    const { usr } = window.history.state as any;
    if (usr && usr.refreshUser) auth();
  }, [location]);

  if (!accessToken) {
    sessionStorage.setItem("redirect", location.pathname);
    return <Navigate to={PATH_LIST.login} />;
  }
  if (isLogined === null) return <Loading />;

  if (!isLogined) {
    // toast.info(notif("sessionExpired"), {
    //   className: "session-expired",
    //   bodyClassName: "session-expired-text",
    //   hideProgressBar: true,
    // });
    sessionStorage.setItem("redirect", location.pathname);
    toast.info(notif("sessionExpired"));
    return <Navigate to={PATH_LIST.login} />;
  }

  return <>{props.children}</>;
}
