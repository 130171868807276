import React, { FC, useRef, memo } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import ResearchImg from "../../assets/Research.jpg";
// import { getMLValue } from "../../helpers/ml";
import { useTranslation } from "react-i18next";
// import { galleryImgURL } from "../../helpers/image";
import { format, parse } from "date-fns";
import { getMLValue, MultiLangField } from "helpers/functions";
import { sircapApi } from "shared/api/sircap";
import { PATH_LIST } from "shared/lib/react-router";
import Clock from "../../../../assets/ClockBlog.svg";
import Eye from "../../../../assets/EyeBlog.svg";
import Calendar from "../../../../assets/CalendarBlog.svg";

interface ICategories {
  id: number;
  name: MultiLangField;
}

type PropsType = {
  title: any;
  subtitle: any;
  categories: ICategories[];
  onCategoryClick: (catId: number) => void;
  duration: any;
  views: any;
  date: any;
  image: any;
  alias: any;
};

const BlogCard: FC<PropsType> = memo(
  ({
    categories,
    onCategoryClick,
    title,
    subtitle,
    duration,
    views,
    date,
    image,
    alias,
  }) => {
    const { t, i18n } = useTranslation("app");
    const navigate = useNavigate();

    const handleNavigate = () => {
      navigate(PATH_LIST.blog(alias));
    };

    return (
      <div className="blogCard">
        <div className="blogCardContainer">
          <div className="blogCardHeader">
            {categories.map((category) => (
              <p
                key={category.id}
                onClick={(e) => {
                  e.stopPropagation();
                  onCategoryClick(category.id);
                }}
              >
                {getMLValue(category.name, i18n.language)}
              </p>
            ))}
          </div>
          <div className="blogCardMain">
            <p onClick={handleNavigate}>{getMLValue(title, i18n.language)}</p>
            <span>{getMLValue(subtitle, i18n.language)}</span>
          </div>
          <div className="blogCardFooter">
            <button onClick={handleNavigate}>{t("readMore")}</button>

            <div>
              <p>
                <img src={Clock} alt="Clock" />
                {duration} {t("minBlog")}.
              </p>
              {/* <p>
                <img src={Eye} alt="Eye" />
                {views}
              </p> */}
              <p>
                <img src={Calendar} alt="Calendar" />
                {date}
              </p>
            </div>
          </div>
        </div>

        <div className="cardImg">
          <img
            className="cardImg"
            src={`${sircapApi.baseUrl}/gallery/${image}`}
            alt="card-image"
          />
        </div>
      </div>
    );
  }
);

export default BlogCard;
