import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { PATH_LIST } from "shared/lib/react-router";
import { sessionModel } from "entities/session";
import { accountStore } from "entities/account";
import { getMLValue } from "helpers/functions";
import Modal from "widgets/auth/modal";
import { PersonalInfoEditCard } from "../components/personal-info-edit-card";
import GoBack from "../../../assets/GoBackApp.svg";
import IconUp from "../../../assets/WhiteVector.svg";
import HistoryIcon from "../../../assets/ClockDark.svg";
import AssignmentsHistory from "../../../assets/AssignmentsHistory.svg";
import "./style.scss";
import Loading from "widgets/loading";
import {
  AssignmentsHistoryField,
  AssignmentsPendingField,
} from "../components/assignment-history-fields";

export const AssignmentsPage = () => {
  const { t, i18n } = useTranslation("app");
  const { t: err } = useTranslation("errors");
  const navigate = useNavigate();
  const {
    isLoading,
    assignments,
    getAssignments,
    assignmentsPending,
    getAssignmentsPending,
    cancelAssignmentContract,
  } = useStore(accountStore);
  const [pendingBottomSheet, setPendingBottomsheet] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");

  const handleNavigate = () => {
    navigate(PATH_LIST.account);
  };
  const handleNavigateToAssignment = (item: any) => {
    navigate(PATH_LIST.assignment(item.type), {
      state: { assignmentType: item.type },
    });
  };

  useEffect(() => {
    getAssignments();
    getAssignmentsPending();
  }, []);

  // useEffect(() => {
  //   console.log(assignmentsPending);
  // }, [assignmentsPending]);

  const onCancelContract = useCallback(
    async (pinCode: string) => {
      setModal(false);
      try {
        await cancelAssignmentContract(
          pinCode,
          err,
          () => {
            navigate(PATH_LIST.account);
          },
          +id
        );
      } catch (error) {
        toast.error("Server Error");
      } finally {
        // navigate(PATH_LIST.assignments);
      }
    },
    [id]
  );

  if (isLoading || !assignments) {
    return <Loading />;
  }

  return (
    <div className="assignments-page">
      <div className="assignments-page-header">
        <img
          className="go-back-assignments"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("assignments")}</p>
        <img
          className="history-assignments"
          onClick={() => {
            navigate(PATH_LIST.assignmentsHistory);
          }}
          src={HistoryIcon}
          alt="history"
        />
      </div>

      <div className="assignments-page-main">
        {assignments.map((item, index) => (
          <PersonalInfoEditCard
            key={index}
            onEdit={() => handleNavigateToAssignment(item)}
            name={getMLValue(item.title, i18n.language)}
            security={true}
          />
        ))}
      </div>

      {assignmentsPending.length > 0 && (
        <div
          className={
            pendingBottomSheet
              ? "assignments-page-footer assignments-page-footer-opened"
              : "assignments-page-footer"
          }
        >
          <div
            onClick={() => {
              setPendingBottomsheet(!pendingBottomSheet);
            }}
            className="assignments-page-footer-box"
          >
            <p>{t("incompleteTransactions")}</p>
            <img src={IconUp} alt="IconUp" />
            {/* <span>
               Aystex karox eq tesnel "mshakvum e" kargavichakov gorcarqner@
              </span>
          */}
          </div>

          <div className="assignments-page-footer-main">
            {assignmentsPending.map((item, index) => {
              return (
                <AssignmentsPendingField
                  key={index}
                  id={item.id}
                  type={item.type}
                  title={item.title}
                  pdf={item.document}
                  date={item.updatedAt}
                  status={item.status}
                  signed={item.signed}
                  request_dto={{
                    amount: item.request_dto?.amount,
                    currency: item.request_dto?.currency,
                    order_type: item.request_dto?.order_type,
                    order_qty: item.request_dto?.order_qty,
                    purchase_currency: item.request_dto?.purchase_currency,
                    security: item.request_dto?.security,
                  }}
                  onCancel={() => {
                    setId(item.id);
                    setModal(true);
                  }}
                  onSign={() => {
                    navigate(
                      PATH_LIST.assignmentSignContract(item.type, item.id)
                    );
                  }}
                />
              );
            })}
          </div>
        </div>
      )}

      {modal && (
        <Modal
          customModal="modalLogOut"
          onClick={onCancelContract}
          onHide={() => {
            setModal(false);
          }}
          title={t("cancel")}
          text={t("enterPin")}
          btnText={t("cancel")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal={true}
          isLoading={isLoading}
          dealError={false}
        />
      )}
    </div>
  );
};
