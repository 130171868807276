import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import Close from "../../../assets/Close.svg";
import Clock from "../../../assets/Clock.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { MarketAsset } from "../components/market-asset";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import Loading from "widgets/loading";
import { AssetType, AssetTypes } from "static/types";
import { navigateToAsset } from "helpers/functions";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

export const AssetSearchPage = () => {
  const { t } = useTranslation("app");
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string>("");
  const [activeBtn, setActiveBtn] = useState("Exchange");
  const inputRef = useRef<HTMLInputElement | null>(null);
  // const [searchValue, setSearchValue] = useState(false);
  // const [debouncedText] = useDebounce(dto, 1000);
  const [debouncedValue] = useDebounce(inputValue, 700);
  const {
    isLoading,
    searchAssets,
    search,
    clearSearchAssets,
    searchHistory,
    setSearchHistory,
    clearSearchHistory,
  } = useStore(marketStore);

  const handleNavigate = () => {
    clearSearchAssets();
    navigate(PATH_LIST.market);
  };
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    if (inputValue && inputValue.length) {
      search(inputValue);
    } else {
      clearSearchAssets();
    }
  }, [debouncedValue]);

  const handleHistoryClick = (search: string) => {
    setInputValue(search);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="asset-search">
      <div className="asset-search-header">
        <img onClick={handleNavigate} src={GoBack} alt="Go Back" />
        <p>{t("assetSearch")}</p>
      </div>
      <div className="asset-search-main">
        <form>
          <input
            ref={inputRef}
            onChange={handleInputChange}
            value={inputValue}
            type="text"
            autoFocus={true}
            onBlur={() => {
              if (!inputValue) return;
              setSearchHistory(inputValue);
            }}
          />
          <img onClick={() => setInputValue("")} src={Close} alt="Close" />
        </form>
        {searchHistory.length > 0 &&
          Object.entries(searchAssets).length == 0 && (
            <div className="search__history">
              <div className="search__history-header">
                <span>{t("recentSearches")}</span>
                <p onClick={clearSearchHistory}>{t("clearAll")}</p>
              </div>
              {searchHistory.map((search, index) => (
                <div
                  onClick={() => handleHistoryClick(search)}
                  className="search__history-row"
                  key={index}
                >
                  {/* <> */}
                  <img src={Clock} alt="Clock" />
                  <span>{search}</span>
                  {/* </> */}

                  {/* <img
                    src={Close}
                    alt="Close"
                    onClick={(e) => {
                      e.stopPropagation();
                      searchHistory.splice(index, 1); 
                    }}
                  /> */}
                </div>
              ))}
            </div>
          )}
        {isLoading && <Loading />}
        {Object.entries(searchAssets).length > 0 && (
          <div className="search-query-container">
            {/* <div className="query-type">
              <button
                className={activeBtn == "Exchange" ? "active-btn" : ""}
                onClick={() => setActiveBtn("Exchange")}
              >
                 {t("exchange")}
              </button>
              <button
                className={activeBtn == "OverCounter" ? "active-btn" : ""}
                onClick={() => setActiveBtn("OverCounter")}
              >
                {t("overTheCounter")}
              </button>
            </div> */}

            {Object.entries(searchAssets)
              .sort((a, b) => a[1].length - b[1].length)
              .map(([key, list]) => (
                <React.Fragment key={key}>
                  <span className="asset-part-title">
                    {/* @ts-ignore */}
                    {t(AssetTypes[key])}
                  </span>
                  {list.map((asset: any) => (
                    <MarketAsset
                      key={asset.id}
                      asset={asset}
                      gains={asset.change}
                      gainsPercent={asset.changesPercentage}
                      showGains={[
                        AssetType.Stock,
                        AssetType.ETF,
                        AssetType.Fond,
                      ].includes(asset.type)}
                      onClick={() =>
                        navigateToAsset(asset.type, asset.id, navigate)
                      }
                    />
                  ))}
                </React.Fragment>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
