import { onboardStore } from "entities/onboard/model/onboardModel";
import { ResponseCodes } from "../sircap";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { PATH_LIST } from "shared/lib/react-router";

export interface GenericErrorModelDto {
  code: ResponseCodes;
  data: {
    error: string;
  };
}

export type ErrorModelDto = GenericErrorModelDto;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  secure?: boolean;
  path: string;
  type?: ContentType;
  query?: QueryParamsType;
  params?: any;
  format?: ResponseFormat;
  body?: unknown;
  baseUrl?: string;
  cancelToken?: CancelToken;
  timeout?: number;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker: (securityData: SecurityDataType | null) => RequestParams;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string | null = null;
  private securityData: SecurityDataType | null = null;
  // @ts-ignore
  private securityWorker: ApiConfig<SecurityDataType>["securityWorker"];
  private customFetch = (...fetchParams: Parameters<typeof axios>) =>
    axios(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType>) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    // const secureParams =
    //   ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
    //     this.securityWorker &&
    //     (await this.securityWorker(this.securityData))) ||
    //   {};
    const secureParams = this.securityWorker(this.securityData);
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        method: params.method,
        // @ts-ignore
        headers: {
          ...(requestParams.headers || {}),
          ...(type
            ? { "Content-Type": type }
            : { "Content-Type": ContentType.Json }),
        },
        data:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    )
      .then((response) => {
        return {
          data: response.data as unknown as T,
          error: null as unknown as E,
        };
      })
      .catch((reason) => {
        if (reason?.response?.data?.code === ResponseCodes.Forbidden) {
          onboardStore.getState().clear();
          toast.info("Your session has expired");
          if (window && window.location) {
            sessionStorage.setItem("redirect", window.location.pathname);
            window.location.replace(PATH_LIST.login);
          }
        }

        return {
          data: null as unknown as T,
          error: reason.response.data as unknown as E,
          status: reason.response.status,
        };
      });
  };

  public postRequest = <T = any, D = any>(path: string) => {
    return (data: T, params: RequestParams = {}) =>
      this.request<D, ErrorModelDto>({
        path: path,
        method: "POST",
        body: data,
        ...params,
      });
  };

  public deleteRequest = <D = any>(path: string) => {
    return (params: RequestParams = {}, data = {}) =>
      this.request<D, ErrorModelDto>({
        path: path,
        method: "DELETE",
        body: data,
        ...params,
      });
  };

  public getRequest = <D = any>(path: string) => {
    return (params: RequestParams = {}) =>
      this.request<D, ErrorModelDto>({
        path: path,
        method: "GET",
        ...params,
      });
  };
}
