import React, { useEffect, useState } from "react";
import "./style.scss";
import GoBackButton from "shared/ui/back-button";
import { PasswordLoginForm } from "widgets/auth/forms";
import LogoAndTitle from "../components/logo-title";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { startAuthentication } from "@simplewebauthn/browser";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { accountStore } from "entities/account";
import { toast } from "react-toastify";
import { onboardModel } from "entities/onboard";

export const PasswordLoginPage = () => {
  const { t } = useTranslation("onboarding-auth");
  const { t: err } = useTranslation("errors");
  const { syncUser, clear } = useStore(onboardModel.onboardStore);
  const { biometric, identificator, setLink } = useStore(
    sessionModel.sessionStore
  );
  const { addUser } = useStore(sessionModel.sessionStore);
  const { synckyc, synckyb } = useStore(accountStore);

  const navigate = useNavigate();
  const HandleNavigate = () => {
    navigate(PATH_LIST.forgotPassword);
  };

  useEffect(() => {
    setLink(PATH_LIST.login);
    return () => setLink("");
  }, []);

  useEffect(() => {
    if (identificator && biometric) {
      handleBiometricsChange();
    }
  }, [identificator, biometric]);

  const handleBiometricsChange = async () => {
    try {
      const response = await sircapApi.auth.loginByBiometric({
        identificator,
      });
      if (response.data) {
        const options = response.data.data;
        const bioResp = await startAuthentication(options);
        const resp = await sircapApi.auth.loginByBiometricVerify({
          identificator,
          id: biometric,
          response: bioResp,
        });
        if (resp.error) {
          switch (resp.error.code) {
            case ResponseCodes.UserIsNotExists:
            case ResponseCodes.WrongEmailOrPassword:
              toast.error(err(String(resp.error.code)));
              break;

            default:
              break;
          }
        } else {
          clear();
          const {
            user,
            accessToken,
            refreshToken,
            entity,
            kyc,
            kyb,
            questionnaireStatus,
          } = resp.data.data;
          syncUser(user, entity);
          synckyc(kyc || null);
          synckyb(kyb || null);
          accountStore.setState({ questionnaireStatus });
          addUser(user, accessToken, refreshToken, entity);

          if (user.status !== 1) {
            navigate(PATH_LIST.registration);
          } else {
            toast.success("Success login");
            const redirect = sessionStorage.getItem("redirect");
            if (redirect) {
              sessionStorage.removeItem("redirect");
              navigate(redirect);
            } else {
              navigate(PATH_LIST.home);
            }
          }
        }
      }

      // toast.error("Failed to start biometric registration");
    } catch (error) {
      console.log(error);
      // toast.error("Failed to start biometric registration");
    }
  };

  return (
    <div className="usingPassword">
      <LogoAndTitle title={t("welcome")} />
      <PasswordLoginForm />
      <span onClick={HandleNavigate} className="forgotPassword">
        {t("forgotYourPassword")}
      </span>
    </div>
  );
};
