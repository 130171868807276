import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { RegisterEmailForm } from "widgets/auth/forms";
import { PasswordField } from "widgets/auth/components";
import { Formik } from "formik";
import { Form } from "formik";
import Button from "shared/ui/button";
import { useStore } from "zustand";
import { accountStore } from "entities/account";
import { useTranslation } from "react-i18next";
import { showNotification } from "helpers/functions";

export const ChangePasswordPage = () => {
  const { changePassword } = useStore(accountStore);
  const { t: err } = useTranslation("errors");
  const { t: o } = useTranslation("onboarding-auth");
  const { t } = useTranslation("app");

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.security);
  };
  const onSubmit = ({ oldPassword, newPassword }: any) => {
    changePassword({ password: oldPassword, newPassword }, err, () => {
      navigate(PATH_LIST.account);
      showNotification(t("passwordChanged"));
    });
  };

  return (
    <div className="change-password">
      <div className="change-password-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("changePassword")}</p>
      </div>
      <div className="change-password-main">
        <div className="warnings">
          <ul>
            <li>{t("atLeast6Chars")}</li>
            <li>{t("containsUpperCase")}</li>
            <li>{t("containsSymbol")}</li>
            <li>{t("containsNumber")}</li>
          </ul>
        </div>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={onSubmit}
          validate={(values) => {
            const errors: any = {};

            // if (values.oldPassword !== prevPassword) {
            //   errors.oldPassword = "Wrong password";
            // }
            // Incorrect password

            if (values.newPassword.length < 6) {
              errors.newPassword = t("valid6Characters");
            }
            if (!/[A-Z]/.test(values.newPassword)) {
              errors.newPassword = t("valid1Upper");
            }
            if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(values.newPassword)) {
              errors.newPassword = t("valid1Symbol");
            }
            if (!/\d/.test(values.newPassword)) {
              errors.newPassword = t("valid1Number");
            }

            if (values.confirmPassword !== values.newPassword) {
              errors.confirmPassword = t("passwordsDoNotMatch");
            }

            return errors;
          }}
        >
          {({ values, errors }) => {
            return (
              <Form>
                <PasswordField
                  label={t("oldPassword")}
                  id="oldPassword"
                  isInvalid={!!errors.oldPassword}
                  placeholder={t("typeOldPassword")}
                  className="first-field"
                  autoComplete="off"
                />
                <div className="forgot-password">
                  <span onClick={() => navigate(PATH_LIST.forgotPassword)}>
                    {o("forgotYourPassword")}
                  </span>
                </div>

                <PasswordField
                  label={t("newPassword")}
                  id="newPassword"
                  isInvalid={!!errors.newPassword}
                  placeholder={t("typeNewPassword")}
                  autoFocus={false}
                  autoComplete="off"
                />
                <PasswordField
                  label={t("confirmPassword")}
                  id="confirmPassword"
                  isInvalid={!!errors.confirmPassword}
                  placeholder={t("confirmYourPassword")}
                  autoFocus={false}
                  autoComplete="off"
                />
                <div className="btn-wrapper">
                  <Button
                    type="submit"
                    disabled={
                      !!errors.oldPassword ||
                      !!errors.newPassword ||
                      !!errors.confirmPassword
                    }
                    variant="dark"
                    className="customBtn"
                  >
                    {t("changePassword")}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
