import React, { FC, useCallback, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import Button from "shared/ui/button";
import { accountStore } from "entities/account";
import Loading from "widgets/loading";
import DocViewer from "@cyntler/react-doc-viewer";
import { useUserName } from "entities/session/model/sessionModel";
import { isMobile } from "react-device-detect";
import Modal from "widgets/auth/modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TermsCheckbox from "pages/onboarding/components/terms-checkbox";
import PDFRenderer from "widgets/pdf-renderer";
import { getRequestDocUrl } from "../../../helpers/functions";

type PDFProps = {
  uri: string;
};

const PDF: React.FC<PDFProps> = React.memo(
  ({ uri }) => {
    return (
      <DocViewer
        className="doc-viewer"
        documents={[{ uri, fileType: "pdf" }]}
        pluginRenderers={[PDFRenderer]}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
          },
          pdfZoom: {
            defaultZoom: isMobile ? 1 : 2,
            zoomJump: 0.2,
          },
          pdfVerticalScrollByDefault: true,
          loadingRenderer: { overrideComponent: () => <Loading /> },
        }}
      />
    );
  },
  (prev, next) => {
    return prev.uri === next.uri;
  }
);

export const AssignmentSignContractPage = () => {
  const { id, type } = useParams();
  const {
    isLoading,
    assignmentContract,
    getAssignmentContractId,
    signAssignmentContract,
  } = useStore(accountStore);
  const [contractHtml, setContractHtml] = useState<string | null>(null);
  const [disable, setDisabled] = useState(false);
  const [modal, setModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { t: err } = useTranslation("errors");
  const { t } = useTranslation("app");

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAssignmentContractId(id!);
  }, []);

  useEffect(() => {
    if (assignmentContract && id) {
      setContractHtml(getRequestDocUrl(id, assignmentContract.document));
    }
  }, [assignmentContract]);

  const onSignContract = useCallback(async (pinCode: string) => {
    setModal(false);
    setDisabled(true);
    try {
      await signAssignmentContract(
        pinCode,
        err,
        () => {
          navigate(PATH_LIST.account);
        },
        +id!
      );
    } catch (error) {
      toast.error("Server Error");
    } finally {
      // navigate(PATH_LIST.assignments);
      setDisabled(false);
      setIsChecked(false);
    }
  }, []);

  const onSignAction = async () => {
    setModal(false);
    setDisabled(true);
    try {
      setModal(true);
    } catch (error) {
      toast.error("Server Error");
      setDisabled(false);
    }
  };

  return (
    <div className="sign-assignment-contract">
      <div className="sign-user-contract-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("sign")}</p>
      </div>
      <div className="sign-user-contract-main">
        {isLoading ? (
          <Loading />
        ) : contractHtml === null ? (
          <div className="not-found-coming-soon">
            <p>{t("notFound")}</p>
          </div>
        ) : (
          <PDF uri={contractHtml} />
        )}
      </div>

      <div className="btn-wrapper deal-btn-wrapper contract-sign-btn-wrapper">
        <TermsCheckbox
          smallFont
          term={t("assignmentsTerm")}
          htmlForId="1"
          isChecked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <Button
          type="submit"
          className="contract-sign-btn"
          variant="dark"
          onClick={onSignAction}
          disabled={disable || isLoading || !isChecked}
        >
          {t("sign")}
        </Button>
      </div>

      {modal && (
        <Modal
          customModal="modalLogOut"
          onClick={onSignContract}
          onHide={() => {
            setModal(false);
            setDisabled(false);
          }}
          title={t("signContract")}
          text={t("enterPin")}
          btnText={t("sign")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal={true}
          isLoading={isLoading}
          dealError={false}
        />
      )}
    </div>
  );
};
