import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  getMLValue,
  getRequestDocUrl,
  MultiLangField,
} from "helpers/functions";
import Successful from "../../../../assets/Successful.svg";
import Pending from "../../../../assets/Pending.svg";
import Rejected from "../../../../assets/Rejected.svg";
import PDF from "../../../../assets/PDFGray.svg";
import "./style.scss";

type PropsType = {
  type:
    | "funds_withdrawal"
    | "fx_trade_order"
    | "equity_trade_order"
    | "security_transfer";
  title: MultiLangField;
  id: string;
  pdf: string;
  date: string;
  status: string;
  request_dto: {
    amount?: number;
    currency?: string;
    purchase_currency?: string;
    order_type?: string;
    order_qty?: number;
    security?: string;
  };
};

export const AssignmentsHistoryField: FC<PropsType> = ({
  type,
  title,
  id,
  pdf,
  status,
  date,
  request_dto,
}) => {
  const { t, i18n } = useTranslation("app");

  const formatDate = (dateString: string): string => {
    return moment(dateString).format("DD.MM.YYYY HH:mm");
  };

  return (
    <a
      href={getRequestDocUrl(id, pdf)}
      target="_blank"
      rel="noopener noreferrer"
      download
      className="assignments-history-field-wrapper"
    >
      <div className="assignments-history-field">
        <div className="field-left">
          <p>{getMLValue(title, i18n.language)}</p>

          <span>
            {request_dto.order_type && t(`${request_dto.order_type}`)}{" "}
            {request_dto?.amount}{" "}
            {!request_dto.security &&
              type !== "fx_trade_order" &&
              request_dto?.currency}{" "}
            {type == "fx_trade_order" && request_dto?.purchase_currency}{" "}
            {request_dto.order_qty && `${request_dto.order_qty} •`}{" "}
            {request_dto?.security}{" "}
          </span>
        </div>
        <div className="field-right">
          <div className="status-field">
            <p
              style={{
                color:
                  status === "success"
                    ? "#27ae60"
                    : status === "pending"
                    ? "#ffa600"
                    : "#eb5757",
              }}
            >
              {t(status)}
            </p>
            <img
              src={
                status == "success"
                  ? Successful
                  : status === "pending"
                  ? Pending
                  : Rejected
              }
              alt={status}
            />
          </div>
          <span className="status-date">
            {formatDate(date)}
            <img src={PDF} alt="pdf" />
          </span>
        </div>
      </div>
    </a>
  );
};

type PendingPropsType = {
  type:
    | "funds_withdrawal"
    | "fx_trade_order"
    | "equity_trade_order"
    | "security_transfer";
  title: MultiLangField;
  id: string;
  pdf: string;
  date: string;
  status: string;
  signed: boolean;
  request_dto: {
    amount?: number;
    currency?: string;
    purchase_currency?: string;
    order_type?: string;
    order_qty?: number;
    security?: string;
  };
  onCancel?: () => void;
  onSign?: () => void;
};

export const AssignmentsPendingField: FC<PendingPropsType> = ({
  type,
  title,
  id,
  pdf,
  status,
  date,
  signed,
  request_dto,
  onCancel,
  onSign,
}) => {
  const { t, i18n } = useTranslation("app");

  const formatDate = (dateString: string): string => {
    return moment(dateString).format("DD.MM.YYYY HH:mm");
  };

  return (
    <>
      {(status == "pending" || status == "on_perfomance") && !signed ? (
        <div className="assignments-history-field-wrapper assignments-pending-field-wrapper">
          <div className="assignments-history-field">
            <div className="field-left">
              <p>{getMLValue(title, i18n.language)}</p>

              <span>
                {request_dto.order_type && t(`${request_dto.order_type}`)}{" "}
                {request_dto?.amount}{" "}
                {!request_dto.security &&
                  type !== "fx_trade_order" &&
                  request_dto?.currency}{" "}
                {type == "fx_trade_order" && request_dto?.purchase_currency}{" "}
                {request_dto.order_qty && `${request_dto.order_qty} •`}{" "}
                {request_dto?.security}{" "}
              </span>
            </div>
            <div className="field-right">
              <div className="status-field">
                <p
                  style={{
                    color:
                      status === "pending" || status === "on_perfomance"
                        ? "#ffa600"
                        : "#eb5757",
                  }}
                >
                  {t(status)}
                </p>
                <img
                  src={
                    status === "pending" || status === "on_perfomance"
                      ? Pending
                      : Rejected
                  }
                  alt={status}
                />
              </div>

              <span
                onClick={() => window.open(getRequestDocUrl(id, pdf), "_blank")}
              >
                {formatDate(date)}
                <img src={PDF} alt="pdf" />
              </span>
            </div>
          </div>

          <div className="pending-btn-wrapper">
            <button
              onClick={onCancel}
              style={{
                color: "rgb(43, 47, 56)",
                background: "rgb(230, 231, 235)",
              }}
            >
              {t("cancel")}
            </button>
            <button
              onClick={onSign}
              style={{
                color: "rgb(249, 249, 249)",
                background: "rgb(0, 15, 48)",
              }}
            >
              {t("sign")}
            </button>
          </div>
        </div>
      ) : (
        <a
          href={getRequestDocUrl(id, pdf)}
          target="_blank"
          rel="noopener noreferrer"
          download
          className="assignments-history-field-wrapper assignments-pending-field-wrapper"
        >
          <div className="assignments-history-field">
            <div className="field-left">
              <p>{getMLValue(title, i18n.language)}</p>

              <span>
                {request_dto.order_type && t(`${request_dto.order_type}`)}{" "}
                {request_dto?.amount}{" "}
                {!request_dto.security &&
                  type !== "fx_trade_order" &&
                  request_dto?.currency}{" "}
                {type == "fx_trade_order" && request_dto?.purchase_currency}{" "}
                {request_dto.order_qty && `${request_dto.order_qty} •`}{" "}
                {request_dto?.security}{" "}
              </span>
            </div>
            <div className="field-right">
              <div className="status-field">
                <p
                  style={{
                    color:
                      status === "pending" || status === "on_perfomance"
                        ? "#ffa600"
                        : "#eb5757",
                  }}
                >
                  {/* {t(status.replace("_", " "))} */}
                  {t(status)}
                </p>
                <img
                  src={
                    status === "pending" || status === "on_perfomance"
                      ? Pending
                      : Rejected
                  }
                  alt={status}
                />
              </div>

              <span>
                {formatDate(date)}
                <img src={PDF} alt="pdf" />
              </span>
            </div>
          </div>
        </a>
      )}
    </>
  );
};
