import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import FilterField from "../components/filter-field";
import Button from "shared/ui/button";
import FilterModal from "../components/filter-modal";
import { useStore } from "zustand";
import { accountStore } from "entities/account";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import { useTranslation } from "react-i18next";
import { FilterType, IBondsFilterData, marketStore } from "entities/market";
import { useDebounce } from "use-debounce";

const defaultForm: IBondsFilterData = {
  currency: null,
  industry: null,
  is_eurobond: false,
  bond_type: null,
  yield_to_matutity: null,
  maturity_date: null,
  coupon: null,
  reliability_rating: null,
  only_debt_amortization: false,
};

export const CreateBondFilterPage = () => {
  const [blockScroll, setBlockScroll] = useState(false);
  const { t } = useTranslation("app");
  const { filterAssetsCount, createdFilter, preloadBondsByFilters, isLoading } =
    useStore(marketStore);

  const dto = (createdFilter?.data as IBondsFilterData) || defaultForm;
  const setDto = (o: any) => {
    marketStore.setState({
      createdFilter: {
        ...createdFilter,
        data: o,
        type: FilterType.Bond,
        changed: true,
      },
    });
  };

  const [debouncedText] = useDebounce(dto, 500);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    (async () => {
      try {
        await preloadBondsByFilters(signal);
      } catch (error) {}
    })();

    return () => {
      abortController.abort();
    };
  }, [debouncedText]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.filter);
  };
  const handleReview = () => {
    marketStore.setState({
      createdFilter: {
        data: dto,
        type: FilterType.Bond,
      },
    });
    navigate(PATH_LIST.filterReview);
  };
  useBlockScrollbar(blockScroll, [blockScroll]);

  const fieldData = [
    {
      id: "currency",
      name: t("Currency"),
      type: "array",
      value: dto.currency,
      onChange: (e: any) => setDto({ ...dto, currency: e }),
    },
    {
      id: "currency",
      name: t("Eurobonds"),
      checkbox: true,
      type: undefined,
      checked: dto.is_eurobond,
      onChange: (e: any) => setDto({ ...dto, is_eurobond: e.target.checked }),
    },
    {
      id: "bondType",
      name: t("Bond type"),
      type: "select",
      value: dto.bond_type,
      onChange: (v: any) => setDto({ ...dto, bond_type: v }),
    },
    {
      id: "yieldMaturity",
      name: t("Yield to maturity"),
      type: "range",
      value: dto.yield_to_matutity,
      onChange: (v: any) => setDto({ ...dto, yield_to_matutity: v }),
    },
    {
      id: "maturityDate",
      name: t("Maturity date"),
      type: "range",
      value: dto.maturity_date,
      onChange: (v: any) => setDto({ ...dto, maturity_date: v }),
    },
    {
      id: "reliabilityRating",
      name: t("Reliability rating"),
      type: "array",
      value: dto.reliability_rating,
      onChange: (v: any) => setDto({ ...dto, reliability_rating: v }),
    },
    // {
    //   id: "industry",
    //   name: t("Industry"),
    //   type: "array",
    //   value: dto.industry,
    //   onChange: (v: any) => setDto({ ...dto, industry: v }),
    //   // onChange: (v: string[] | null) => setDto({ ...dto, industry: v }),
    // },
    {
      id: "coupon",
      name: t("Coupon"),
      type: "select",
      value: dto.coupon,
      onChange: (v: any) => setDto({ ...dto, coupon: v }),
    },
  ];

  return (
    <div className="create-filter">
      <div className="create-filter-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("newFilter")}</p>
        {/* <span>{t("indicatorsSelected")}: 0</span> */}
      </div>
      <div
        className={
          blockScroll
            ? "create-filter-main"
            : "create-filter-main disable-scroll"
        }
      >
        <p className="filter-title">{t("Bonds")}</p>

        {fieldData.map(
          ({ name, value, onChange, checkbox, checked, type, id }, index) => (
            <FilterField
              key={index}
              id={id}
              onChange={onChange}
              // @ts-ignore
              type={type}
              name={name}
              // @ts-ignore
              value={value}
              checkbox={checkbox}
              checked={checked}
            />
          )
        )}

        <div className="btn-wrapper">
          <Button
            onClick={handleReview}
            type="submit"
            disabled={filterAssetsCount == 0 || isLoading}
            variant="dark"
            className="customBtn"
          >
            {t("show")}{" "}
            {`${filterAssetsCount} ${t("results").toLocaleLowerCase()}`}
          </Button>
        </div>
      </div>
    </div>
  );
};
