import React, { FC, useEffect, useState, useCallback } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import ChevronDown from "../../../assets/ChevronDown.png";

import { useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import { PriceTypeModal } from "../components/price-type";
import {
  AssetType,
  IccContractType,
  OperationType,
  OrderType,
} from "static/types";
import { dashboardStore } from "entities/dashboard";
import { useStore } from "zustand";
import { marketStore, useTariff } from "entities/market";
import {
  getCommission,
  getPreviewIccContractUrl,
  getRatePrice,
  navigateToAsset,
} from "helpers/functions";
import { formatMoney } from "helpers/money";
import Loading from "widgets/loading";
import Modal from "widgets/auth/modal";
import { useTranslation } from "react-i18next";
import { ResponseCodes } from "shared/api/sircap";
import { toast } from "react-toastify";
import TermsCheckbox from "pages/onboarding/components/terms-checkbox";
import useCanAction from "hooks/useCanAction";
import { sessionModel } from "entities/session";
import Currency from "../components/deposit-currency";
import { CurrencyModal } from "../components/currency-modal";

const OrderTypes = {
  [OperationType.Buy]: OrderType.Market,
  [OperationType.Sell]: OrderType.Market,
};

export const InvestPage = () => {
  const [checked, setChecked] = useState(false);
  const [isProtected, setIsProtected] = useState(false);
  // const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [type, setType] = useState("");
  const { asset_id, operation } = useParams();
  const [confirmDeal, setConfirmDeal] = useState(false);
  const { user_deposit_accounts, user_brokerage_accounts, loadUserAccounts } =
    useStore(dashboardStore);
  const { isLoading, assetInfo, loadAssetInfo, openOrder } =
    useStore(marketStore);
  const currSymbol = assetInfo?.asset?.currency?.symbol || "";
  const { t: tError } = useTranslation("errors");
  const { t } = useTranslation("app");
  const [load, setLoad] = useState(false);
  const [dealError, setDealError] = useState(false);
  const action = useCanAction();
  const [selectedIndex, setSelectedIndex] = useState("IPO_INDEX");

  const [indexModal, setIndexModal] = useState<null | "custom">(null);

  const { user, entity } = useStore(sessionModel.sessionStore);

  const symbol = assetInfo?.asset?.symbol || "";
  const currencySymbol = assetInfo?.asset?.currency?.symbol;
  const asset = user_brokerage_accounts?.find(
    (ua) => ua.asset.symbol === symbol
  );
  const indexList =
    user_brokerage_accounts?.filter(
      (ua) =>
        ["AI_INDEX", "IPO_INDEX"].includes(ua.asset.symbol) &&
        ua.asset.data.capital_protection
    ) || [];
  const selectedIndexAsset = user_brokerage_accounts?.find(
    (ua) => ua.asset.symbol === selectedIndex
  );
  const annualYield = assetInfo?.asset?.data?.annual_yield;
  const isOptionIndex = assetInfo?.asset?.symbol === "INDEX_OPTION";
  const capitalProtection =
    isOptionIndex && operation === OperationType.Buy
      ? selectedIndexAsset?.asset?.data?.capital_protection
      : assetInfo?.asset?.data?.capital_protection;
  const capitalProtectionCommission =
    assetInfo?.asset?.data?.capital_protection_commission;

  useEffect(() => {
    try {
      loadAssetInfo(asset_id);
      loadUserAccounts();
    } catch (error) {
      navigate(-1);
    }
  }, []);

  const getUserAccount = useCallback(() => {
    const curr = assetInfo?.asset?.currency.code;
    const acc = user_deposit_accounts.find((acc) => acc.currency.code === curr);
    return {
      symbol: curr,
      amount: formatMoney(acc?.amount || 0),
    };
  }, [user_deposit_accounts, assetInfo]);

  const getAssetPriceAsk = useCallback(() => {
    try {
      const info = assetInfo?.asset;
      if (info) {
        const { ai, nominal } = info?.data;
        return formatMoney((info.ask / 100) * nominal + ai);
      }
      return 0;
    } catch (error) {
      return 0;
    }
  }, [assetInfo]);

  const getAssetPriceBid = useCallback(() => {
    try {
      const info = assetInfo?.asset;
      if (info) {
        const { ai, nominal } = info?.data;
        return formatMoney((info.bid / 100) * nominal + ai);
      }
      return 0;
    } catch (error) {
      return 0;
    }
  }, [assetInfo]);

  const getAssetPrice = useCallback(() => {
    if (operation === OperationType.Buy) {
      return getAssetPriceAsk();
    } else if (operation === OperationType.Sell) {
      return getAssetPriceBid();
    } else {
      return 0;
    }
  }, [assetInfo, operation]);

  const getTotalQuantity = useCallback((): number => {
    try {
      const price = getAssetPrice();
      const q = amount! / price;
      if (!q) return 0;
      return parseFloat(q.toFixed(4));
    } catch (error) {
      return 0;
    }
  }, [amount, assetInfo]);

  const getAvailableLots = useCallback(() => {
    try {
      const price = getAssetPrice();
      const balance = getUserAccount().amount;
      const [_, __, commision] = getCommission(
        assetInfo?.commission || 0,
        balance,
        OperationType.Buy,
        assetInfo?.minimal_commission || 0,
        false
      );
      const lots = (balance - commision) / (price * 1);
      if (lots === Infinity || lots < 0) return 0;
      return lots;
    } catch (error) {
      return 0;
    }
  }, [assetInfo, getUserAccount]);

  const getTotalAmount = () => {
    try {
      return +(asset!.quantity * asset!.price).toFixed(2);
    } catch (error) {
      return 0;
    }
  };

  const getAmountRange = useCallback(() => {
    try {
      if (operation === OperationType.Buy) {
        const price = getAssetPrice();
        const minAmount = assetInfo?.asset?.data?.minimal_invest;
        const maxAmount = +(getAvailableLots() * price).toFixed(4);
        if (!minAmount && !maxAmount) return [0, 0];
        return [minAmount, maxAmount];
      } else {
        const minAmount = 0;
        const maxAmount = getTotalAmount();
        if (!maxAmount) return [0, 0];
        return [minAmount, maxAmount];
      }
    } catch (error) {
      return [0, 0];
    }
  }, [assetInfo, getAvailableLots, getAssetPrice, getTotalAmount]);

  const getCommissionWrap = useCallback(() => {
    try {
      let addCommission = 0;
      try {
        if (isOptionIndex && operation === OperationType.Buy) {
          return getCommission(
            assetInfo?.commission || 0,
            (selectedIndexAsset?.quantity || 0) *
              (selectedIndexAsset?.price || 0),
            operation!,
            assetInfo?.asset?.minimal_commission || 0,
            false
          );
        } else if (isProtected && capitalProtection) {
          // estimate capital protection amount commission
          const commission = (amount / 100) * capitalProtectionCommission;
          if (commission) {
            addCommission = +commission.toFixed(2);
          }
        }
      } catch (error) {
        console.log(error);
      }

      return getCommission(
        assetInfo?.commission || 0,
        amount!,
        operation!,
        assetInfo?.asset?.minimal_commission || 0,
        false,
        addCommission
      );
    } catch (error) {
      return [0, 0, 0];
    }
  }, [
    assetInfo,
    amount,
    isProtected,
    capitalProtection,
    capitalProtectionCommission,
    isOptionIndex,
  ]);

  const getTotalPriceAndCommission = (): string => {
    try {
      const total = amount;
      if (!total) return "";
      const [_, additional, comm] = getCommissionWrap();
      const commission = comm + additional;
      const protection = amount / 10;

      return `${+(total + commission + (isProtected ? protection : 0)).toFixed(
        2
      )}${currSymbol}`;
    } catch (error) {
      return "";
    }
  };

  const getAssetCommission = () => {
    try {
      const [commission, additional, amount] = getCommissionWrap();
      if (!amount) return <></>;

      return (
        <div className="deal-commission">
          {amount > assetInfo!.asset!.minimal_commission ? (
            <p>
              {t("commission")}: {commission}%{" "}
              {additional ? `+ ${additional}$` : ""}
            </p>
          ) : (
            <p>
              {" "}
              {t("commission")}: {additional ? `+ ${additional}$` : ""}
            </p>
          )}
          <span>
            {amount}
            {assetInfo!.asset!.currency.symbol}
          </span>
        </div>
      );
    } catch (error) {
      return <></>;
    }
  };

  const getAssetYield = useCallback(() => {
    try {
      const info = assetInfo?.asset;
      if (info) {
        const { annual_yield } = info?.data;
        const yield_v = formatMoney(annual_yield);

        return (
          <div className="deal-commission">
            <p>
              {t("yield")}: {yield_v}%
            </p>
            {/* <span>
                {amount}
                {assetInfo!.asset!.currency.symbol}
              </span> */}
          </div>
        );
      }
      return <></>;
    } catch (error) {
      return <></>;
    }
  }, [assetInfo]);

  useEffect(() => {
    if (
      assetInfo?.asset?.symbol === "INDEX_OPTION" &&
      operation === OperationType.Buy &&
      selectedIndexAsset
    ) {
      if (isProtected) {
        const capProtection =
          selectedIndexAsset.asset.data?.capital_protection || 0;
        setAmount(
          ((selectedIndexAsset.quantity * selectedIndexAsset.price) / 100) *
            capProtection
        );
      } else {
        setAmount(0);
      }
    }
  }, [assetInfo, selectedIndexAsset, isProtected]);

  const getIndexInvestments = () => {
    const indexAsset = user_brokerage_accounts.find(
      (v) => v.asset.symbol === selectedIndex
    );
    if (indexAsset) {
      return indexAsset.quantity * indexAsset.price;
    } else {
      return 0;
    }
  };

  const handleBuy = (pinCode: string) => {
    if (assetInfo?.asset && operation) {
      setLoad(true);
      openOrder(
        assetInfo.asset.id,
        {
          pinCode,
          comments: "",
          asset: assetInfo.asset.id,
          type: OrderTypes[operation as OperationType],
          price: getAssetPrice(),
          quantity: getTotalQuantity(),
          operation: operation!,
          with_sirius: false,
          use_capital_protection: capitalProtection && isProtected,
          custom_commission: isOptionIndex ? getCommissionWrap()[2] : undefined,
        },
        tError,
        (error?: number) => {
          setLoad(false);
          if (error) {
            if (error === ResponseCodes.WrongPinCode) {
              setDealError(true);
            } else {
              const err = String(error);
              toast.error(tError(err));
            }
          } else {
            toast.success("Success");
            navigate(PATH_LIST.strategy(asset_id!));
          }
        }
      );
    }
  };

  const unavailable = useCallback(() => {
    const totalAmount = parseFloat(getTotalPriceAndCommission());
    const userBalance = getUserAccount().amount as number;
    const [min, max] = getAmountRange();
    if (operation === OperationType.Buy) {
      if (!amount || amount < 0 || !userBalance || !totalAmount || amount < min)
        return true;
      return totalAmount > userBalance;
    } else {
      if (!amount || amount < 0 || !totalAmount || amount < min) return true;
      return amount > max;
    }
  }, [amount, getTotalPriceAndCommission, getUserAccount, getAmountRange]);

  if (isLoading) return <Loading />;

  return (
    <div className="deal-container strategy-invest-deal-container">
      <div className="deal-header">
        <img
          className="go-back-portfolio"
          onClick={() => navigate(-1)}
          src={GoBack}
          alt="Go Back"
        />
        <div>
          {/* <p>{symbol}</p>
          <div>
            <span>
              {getAssetPriceAsk()}
              {currSymbol}
            </span>
            {getAssetDayChange().percent && (
              <span className={getAssetDayChange().isLoss ? "loss" : ""}>
                {getAssetDayChange().percent}
              </span>
            )}
          </div> */}
          <p>{t("invest")}</p>
        </div>
      </div>
      <div className="deal-main">
        <div className="deal-on-account">
          <span className="title-span">{t("account")}</span>
          <div className="deal-on-account-box">
            <div>
              <span>{t("mainAccount")}</span>
              <p>
                {getUserAccount().amount}
                {currSymbol}
              </p>
            </div>
          </div>
        </div>

        {isOptionIndex && operation === OperationType.Buy ? (
          <>
            <div style={{ marginTop: "14px" }}>
              {indexList?.length ? (
                <Currency
                  image={selectedIndexAsset?.asset?.image || ""}
                  onClick={() => setIndexModal("custom")}
                  custom={selectedIndex}
                  customText={t("")}
                />
              ) : (
                <div className="bargaining-alert">
                  <span>{t("haveNoInvestments")}</span>
                </div>
              )}
            </div>

            <div className="current-investment-amount">
              <label>
                {t("currentInvestmentAmount")}{" "}
                {selectedIndexAsset?.asset?.name || ""}
              </label>
              <div>
                <p>
                  {getIndexInvestments()} {currencySymbol}
                </p>
              </div>
            </div>

            <TermsCheckbox
              className="custom-strategy-checkbox"
              term={t("iConfirmThat")}
              CustomName={t("document")}
              CustomDoc={getPreviewIccContractUrl(
                user!.id,
                entity,
                amount || 0,
                symbol
              )}
              htmlForId="1"
              isChecked={checked}
              onChange={() => setChecked(!checked)}
            />

            <TermsCheckbox
              className="capital-protection-checkbox"
              term={t("capitalProtectionText")}
              disabled={!selectedIndexAsset || !selectedIndexAsset?.quantity}
              onClick={() =>
                navigate(PATH_LIST.strategy(asset_id), {
                  state: { tab: "protection" },
                })
              }
              onClickText={`(${t("description").toLowerCase()})`}
              htmlForId="2"
              isChecked={isProtected}
              onChange={() => {
                setIsProtected(!isProtected);
              }}
            />

            {isProtected && (
              <>
                <div className="deal-commission">
                  <p>
                    {t("capitalProtectionAmount", { cap: capitalProtection })}:{" "}
                    <b>
                      {amount}
                      {currencySymbol}
                    </b>
                  </p>
                </div>

                {getAssetCommission()}
              </>
            )}
          </>
        ) : (
          <>
            {asset && asset.quantity > 0 && (
              <div className="current-investment-amount">
                <label>{t("currentInvestmentAmount")}</label>
                <div>
                  <p>
                    {getTotalAmount()} {currencySymbol}
                  </p>
                  {/* <span>
                {annualYield}% {t("annualReturn")}
              </span> */}
                </div>
              </div>
            )}

            <label htmlFor="amount">
              {operation === OperationType.Buy
                ? t("enterAmountToInsvest")
                : t("enterNewAmount")}
              <br />
              {operation === OperationType.Buy && (
                <>
                  {t("min")}: {getAmountRange()[0]}
                  {currencySymbol} <br />
                </>
              )}
              {getAmountRange()[1] < getAmountRange()[0] ? (
                <div className="bargaining-alert">
                  <span>{t("insufficientFunds")}</span>
                </div>
              ) : (
                <>
                  {t("max")}: {getAmountRange()[1]}
                  {currencySymbol}
                </>
              )}
            </label>
            <div className="price-box">
              <input
                id="amount"
                className="specific-price-input"
                type="number"
                value={amount}
                onChange={(e) => {
                  // const newValue = e.target.value.replace(/[^0-9.]/g, "");
                  // setSpecificPrice(parseFloat(newValue));
                  const strValue = String(e.target.value);
                  const value = +strValue || "";
                  setAmount(value as number);
                }}
                min={getAmountRange()[0]}
                max={getAmountRange()[1]}
                step={5000}
                autoFocus={true}
              />
              <span>{currSymbol}</span>
            </div>
            {/* {getAssetYield()} */}
            {getAssetCommission()}

            {operation === OperationType.Buy && (
              <TermsCheckbox
                className="custom-strategy-checkbox"
                term={t("iConfirmThat")}
                CustomName={t("document")}
                CustomDoc={getPreviewIccContractUrl(
                  user!.id,
                  entity,
                  amount || 0,
                  symbol
                )}
                htmlForId="1"
                isChecked={checked}
                onChange={() => setChecked(!checked)}
              />
            )}
            {capitalProtection && operation === OperationType.Buy && (
              <TermsCheckbox
                className="capital-protection-checkbox"
                term={t("capitalProtectionText")}
                onClick={() =>
                  navigate(PATH_LIST.strategy(asset_id), {
                    state: { tab: "protection" },
                  })
                }
                onClickText={`(${t("description").toLowerCase()})`}
                htmlForId="2"
                isChecked={isProtected}
                onChange={() => setIsProtected(!isProtected)}
              />
            )}

            {isProtected && (
              <div className="deal-commission">
                <p>
                  {t("capitalProtectionAmount", { cap: capitalProtection })}:{" "}
                  <b>
                    {amount / 10}
                    {currencySymbol}
                  </b>
                </p>
              </div>
            )}
          </>
        )}
      </div>

      <div className="btn-wrapper deal-btn-wrapper">
        <Button
          type="submit"
          disabled={
            unavailable() ||
            isLoading ||
            (operation === OperationType.Buy && !checked)
          }
          variant="dark"
          className="customBtn deal-btn"
          onClick={() => action(() => setConfirmDeal(true))}
        >
          {operation === OperationType.Buy
            ? t("confirmInvestment")
            : t("confirm")}
          <span>{getTotalPriceAndCommission()}</span>
        </Button>
      </div>

      {confirmDeal === true && (
        <Modal
          customModal="modalLogOut"
          onClick={handleBuy}
          onHide={() => setConfirmDeal(false)}
          title={t("confirmDeal")}
          text={t("toCompleteDeal")}
          btnText={t("Confirm")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal={true}
          isLoading={load}
          dealError={dealError}
        />
      )}

      {indexModal && (
        <CurrencyModal
          type={indexModal}
          onClose={() => setIndexModal(null)}
          onCurrencyChange={(c) => setSelectedIndex(c)}
          selectedCustom={selectedIndex}
          customList={
            indexList?.map((ua) => ({
              id: ua.asset.symbol,
              name: ua.asset.name,
              image: ua.asset.image,
            })) || []
          }
          customText="Select INDEX"
          selectedCurrency={""}
        />
      )}
    </div>
  );
};
