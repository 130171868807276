import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import DatePicker from "../components/date-picker";
import { accountStore } from "entities/account";
import { Spinner } from "widgets/loading";
import { useTranslation } from "react-i18next";
import { AssignmentsHistoryField } from "../components/assignment-history-fields";

export const AssignmentsHistoryPage = () => {
  const { type } = useParams();
  const { t } = useTranslation("app");
  const navigate = useNavigate();
  const { isLoading, assignmentsHistory, getAssignmentsHistory } =
    useStore(accountStore);

  const handleNavigate = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAssignmentsHistory(type && type);
  }, []);

  // useEffect(() => {
  //   console.log(assignmentsHistory[0]);
  // }, [assignmentsHistory]);

  // todo add pagination later
  // (page: number, limit: number)

  return (
    <div className="assignments-history-page">
      <div className="assignments-history-header">
        <img
          className="go-back"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("history")}</p>
        {/* <img
          className="search-history"
          onClick={handleSearch}
          src={Search}
          alt="Search"
        />
        <img
          className="search-history"
          onClick={handleSearch}
          src={Filter}
          alt="Filter"
        /> */}
      </div>

      <div className="assignments-history-main">
        {isLoading ? (
          <div className="history-loader">
            <Spinner />
          </div>
        ) : Array.isArray(assignmentsHistory) &&
          assignmentsHistory.length > 0 ? (
          assignmentsHistory.flat().map((item) => (
            <AssignmentsHistoryField
              key={item.id}
              id={item.id}
              type={item.type}
              title={item.title}
              pdf={item.document}
              date={item.updatedAt}
              status={item.status}
              request_dto={{
                amount: item.request_dto?.amount,
                currency: item.request_dto?.currency,
                order_type: item.request_dto?.order_type,
                order_qty: item.request_dto?.order_qty,
                purchase_currency: item.request_dto?.purchase_currency,
                security: item.request_dto?.security,
              }}
            />
          ))
        ) : (
          <div className="not-found-coming-soon">
            <p>{t("noData")}</p>
          </div>
        )}
      </div>
    </div>
  );
};
