import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import FilterField from "../components/filter-field";
import Button from "shared/ui/button";
import { FilterType, IStocksFilterData, marketStore } from "entities/market";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { useDebounce } from "use-debounce";
import { sircapApi } from "shared/api/sircap";

const defaultForm: IStocksFilterData = {
  segment: null,
  industry: null,
  country: null,
  dividend_yield: null,
  growth: false,
  value: false,
  market_cap: null,
  sort_by: "",
  order_by: "",
};

export const CreateStockFilterPage = () => {
  const { t } = useTranslation("app");
  const {
    filterAssetsCount,
    createdFilter,
    preloadAssetsByFilters,
    isLoading,
  } = useStore(marketStore);

  useEffect(() => {
    marketStore.setState({
      createdFilter: {
        ...createdFilter,
        data: defaultForm,
        type: FilterType.Stock,
        changed: true,
      },
    });
  }, [])

  const dto = (createdFilter?.data as IStocksFilterData) || defaultForm;
  const setDto = (o: any) => {
    marketStore.setState({
      createdFilter: {
        ...createdFilter,
        data: o,
        type: FilterType.Stock,
        changed: true,
      },
    });
  };

  // const changesCount = Object.values(defaultForm)
  //   .map((v, i) => v === Object.values(dto)[i])
  //   .filter((v) => !v).length;s

  const [debouncedText] = useDebounce(dto, 500);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    (async () => {
      try {
        await preloadAssetsByFilters(signal);
      } catch (error) { }
    })();

    return () => {
      abortController.abort();
    };
  }, [debouncedText]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.filter);
  };
  const handleReview = () => {
    navigate(PATH_LIST.filterReview);
  };

  return (
    <div className="create-filter">
      <div className="create-filter-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("newFilter")}</p>
        {/* <span>
          {t("indicatorsSelected")}: {changesCount}
        </span> */}
      </div>
      <div className={"create-filter-main"}>
        <p className="filter-title">{t("Stocks")}</p>
        <FilterField
          id="segment"
          onChange={(v: any[] | null) => {
            setDto({
              ...dto,
              segment: v ? v?.map((i) => i.alias as string) : null,
            });
          }}
          type="array"
          name={t("Segment")}
          value={dto.segment}
        />
        <FilterField
          id="stockIndustry"
          onChange={(v: any[] | null) => {
            setDto({
              ...dto,
              industry: v ? v?.map((i) => i.alias as string) : null,
            });
          }}
          type="array"
          name={t("Industry")}
          value={dto.industry}
        />
        <FilterField
          id="country"
          onChange={(v: string[] | null) => setDto({ ...dto, country: v })}
          type="array"
          name={t("Country")}
          value={dto.country}
        />
        <FilterField
          id="dividendYield"
          onChange={(v: any) => setDto({ ...dto, dividend_yield: v })}
          type="range"
          name={t("Dividend yield")}
          value={dto.dividend_yield}
        />
        <FilterField
          id={"growth"}
          onChange={(e: any) => setDto({ ...dto, growth: e.target.checked })}
          name={t("Growth")}
          checkbox={true}
          checked={dto.growth}
        />
        <FilterField
          id={"value"}
          onChange={(e: any) => setDto({ ...dto, value: e.target.checked })}
          name={t("Value")}
          checkbox={true}
          checked={dto.value}
        />
        <FilterField
          id="marketCap"
          onChange={(v: any) => setDto({ ...dto, market_cap: v })}
          type="range"
          name={t("Market cap")}
          value={dto.market_cap}
        />
      </div>
      <div className="btn-wrapper">
        <Button
          onClick={handleReview}
          type="submit"
          disabled={filterAssetsCount == 0 || isLoading}
          variant="dark"
          className="customBtn"
        >
          {t("show")}{" "}
          {`${filterAssetsCount} ${t("results").toLocaleLowerCase()}`}
        </Button>
      </div>
    </div>
  );
};
