import { sircapApi } from "shared/api/sircap";
import { EntityType } from "static/types";
import { createStore, useStore } from "zustand";
import { persist } from "zustand/middleware";
import { IAsset } from "entities/market";
import { MultiLangValueType } from "helpers/functions";

interface ICurrency {
  id: number;
  code: string;
  flag: string | null;
  symbol: string;
}
// interface IRate {
//   id: number;
//   info_source: any;
//   price: number;
//   price_asset: ICurrency;
//   trade_asset: ICurrency;
//   date: string;
// }
export interface IRate {
  pair: string;
  from: string;
  to: string;
  bid: number;
  ask: number;
}
interface IUserRate {
  price_asset: string;
  trade_asset: string;
}

export interface IUserDepositAccount {
  id: number;
  user_id: number;
  user_type: EntityType;
  currency: ICurrency;
  amount: number;
  blocked_amount: number;
}
export interface IUserBrokerageAccount {
  id: number;
  user_id: number;
  user_type: EntityType;
  asset: IAsset;
  quantity: number;
  blocked_quantity: number;
  date: Date;
  price: number;
}

enum Currency {
  AMD = "AMD",
  USD = "USD",
  EUR = "EUR",
  RUB = "RUB",
}

const defaultUserRates: IUserRate[] = [
  { trade_asset: Currency.RUB, price_asset: Currency.AMD },
  { trade_asset: Currency.USD, price_asset: Currency.AMD },
  { trade_asset: Currency.EUR, price_asset: Currency.AMD },
];

const defaultUserAccountsShowConfig = {
  AMD: true,
};

interface IStory {
  id: number;
  createdAt: string;
  title: MultiLangValueType;
  is_active: boolean;
  use_multi_lang_images: boolean;
  preview_images: MultiLangValueType;
  preview_image: string | null;
  sort_id: number;
  storyItems: Array<{
    sort_id: number;
    use_content: boolean;
    content: MultiLangValueType;
    link: MultiLangValueType;
    link_text: MultiLangValueType;
    use_multi_lang_images: boolean;
    action_link_text: MultiLangValueType;
    web_action_link: string | null;
    web_action_params: any | null;
    content_image: string | null;
    content_images: MultiLangValueType;
    text: MultiLangValueType;
  }>;
}

type DashboardState = {
  prevPathDeal: boolean;
  setPrevPathDeal: any;
  currencies: ICurrency[];
  all_rates: IRate[];
  user_rates: IUserRate[];
  user_deposit_accounts: IUserDepositAccount[];
  user_brokerage_accounts: IUserBrokerageAccount[];
  user_non_trading_accounts: IUserBrokerageAccount[];
  user_accounts_show_config: Record<string, boolean>;
  balanceCurrency: string;
  stories: IStory[];

  setBalanceCurrency: (v: string) => void;
  updateRates: (list: IUserRate[]) => void;
  updateAccountsShowConfig: (list: Record<string, boolean>) => void;
  loadUserAccounts: () => Promise<void>;
  loadCurrencies: Function;
  loadAllRates: Function;
  loadStories: Function;
};

export const dashboardStore = createStore<DashboardState>()(
  persist(
    (set, get) => ({
      prevPathDeal: false,
      setPrevPathDeal: (value: boolean) => {
        set({ prevPathDeal: value });
      },
      currencies: [],
      all_rates: [],
      user_rates: defaultUserRates,
      user_deposit_accounts: [],
      user_brokerage_accounts: [],
      user_non_trading_accounts: [],
      user_accounts_show_config: defaultUserAccountsShowConfig,
      balanceCurrency: Currency.AMD,
      stories: [],

      setBalanceCurrency: (v: string) => {
        set({ balanceCurrency: v });
      },

      loadCurrencies: async () => {
        const resp = await sircapApi.dashboard.currencies();

        if (resp.error) {
        } else {
          set({ currencies: resp.data.data });
        }
      },

      loadAllRates: async () => {
        const resp = await sircapApi.dashboard.rates();

        if (resp.error) {
        } else {
          set({ all_rates: resp.data.data });
        }
      },

      loadUserAccounts: async () => {
        try {
          const resp = await sircapApi.dashboard.accounts({
            timeout: 10 * 1000,
          });

          if (resp.error) {
          } else {
            const [depositAccounts, brokerageAccounts, nonTradingAccounts] =
              resp.data.data as [
                IUserDepositAccount[],
                IUserBrokerageAccount[],
                IUserBrokerageAccount[]
              ];
            if (depositAccounts.length) {
              const cash = Object.fromEntries(
                depositAccounts.map((v) => [
                  v.currency.code,
                  v.currency.code === "AMD" || !!v.amount,
                ])
              );
              const old_config = get().user_accounts_show_config;

              set({
                user_deposit_accounts: depositAccounts,
                user_brokerage_accounts: brokerageAccounts,
                user_non_trading_accounts: nonTradingAccounts,
                user_accounts_show_config: { ...old_config, ...cash },
              });
            } else {
              set({
                user_deposit_accounts: depositAccounts,
                user_brokerage_accounts: brokerageAccounts,
                user_non_trading_accounts: nonTradingAccounts,
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      },

      updateRates: (list) => {
        set({ user_rates: list });
      },

      updateAccountsShowConfig: (list) => {
        set({ user_accounts_show_config: list });
      },

      loadStories: async () => {
        try {
          const resp = await sircapApi.dashboard.stories();

          if (resp.error) {
          } else {
            set({
              stories: (resp.data.data as IStory[]).filter((s) => s.is_active),
            });
          }
        } catch (error) {}
      },
    }),
    {
      name: "dashboard",
      version: 1,
      merge: (persistedState, currState) => {
        const {
          user_accounts_show_config,
          currencies,
          user_rates,
          balanceCurrency,
        } = persistedState as Partial<DashboardState>;

        return {
          ...currState,
          user_rates: user_rates?.length ? user_rates : defaultUserRates,
          currencies: currencies || [],
          balanceCurrency: balanceCurrency || Currency.AMD,

          user_accounts_show_config: Object.keys(
            user_accounts_show_config || {}
          ).length
            ? user_accounts_show_config || {}
            : defaultUserAccountsShowConfig,
        };
      },
    }
  )
);

export const useCurrencies = () =>
  useStore(dashboardStore, (state) => state.currencies);

export const useRatesCurrencies = () =>
  useStore(dashboardStore, (state) =>
    state.currencies.filter((v) => Object.keys(Currency).includes(v.code))
  );
