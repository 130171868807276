import React, { useEffect, useRef, useState } from "react";
import "./styel.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Author from "../../../assets/DefaultAuthor.svg";
import GoBack from "../../../assets/GoBackApp.svg";
import { format, parse, isValid, Locale } from "date-fns";
import { enUS, ru, hy } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { sircapApi } from "shared/api/sircap";
import DocViewer from "@cyntler/react-doc-viewer";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import Loading from "widgets/loading";
import { getMLValue } from "helpers/functions";
import Link from "../../../assets/ShareLink.svg";
import Mail from "../../../assets/ShareMail.svg";
import TG from "../../../assets/ShareTG.svg";
import Print from "../../../assets/Print.svg";
import PageUp from "../../../assets/PageUp.svg";
import ShareBtn from "../components/share-btn";
import { toast } from "react-toastify";
import PDFRenderer from "widgets/pdf-renderer";

export const Disclaimer = {
  en: "DisclaimerReEN.pdf",
  ru: "DisclaimerReRU.pdf",
  am: "DisclaimerReAM.pdf",
};

type PDFProps = {
  uri: string;
};

const PDF: React.FC<PDFProps> = React.memo(
  ({ uri }) => {
    return (
      <DocViewer
        className="doc-viewer"
        documents={[{ uri, fileType: "pdf" }]}
        pluginRenderers={[PDFRenderer]}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
          },
          pdfZoom: {
            defaultZoom: 1,
            zoomJump: 0.2,
          },
          pdfVerticalScrollByDefault: true,
          loadingRenderer: { overrideComponent: () => <Loading /> },
        }}
      />
    );
  },
  (prev, next) => prev.uri === next.uri
);

export const ResearchPage = () => {
  const navigate = useNavigate();
  const { alias } = useParams();
  const { i18n } = useTranslation();
  const { t } = useTranslation("app");
  const { loadResearchByAlias, researchByAlias, loadMembers, members } =
    useStore(marketStore);

  useEffect(() => {
    loadResearchByAlias(alias!);
    loadMembers();
  }, [alias]);

  // * <Constants>
  const baseUrl = window.location;
  const telegramShareLink = `https://t.me/share/url?url=${baseUrl}&text=Discover New Insights from Sirius Capital`;
  const emailShareLink = `mailto:?subject=${encodeURIComponent(
    "Discover New Insights from Sirius Capital"
  )}&body=${encodeURIComponent(
    `Discover New Insights from Sirius Capital: ${baseUrl}`
  )}`;
  const handleCopyLink = () => {
    const currentPath = window.location.href;
    navigator.clipboard.writeText(currentPath).then(
      () => {
        toast.success("Link copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy link: ", err);
      }
    );
  };
  // * </Constants>

  const contentRef = useRef<any>(null);
  const disclaimerRef = useRef<any>(null);
  const [hideBottomsheet, setHideBottomsheet] = useState(false);
  const [styleBtn, setStyleBtn] = useState(false);

  const pageUp = () => {
    const contentElement = document.querySelector(".research-page-content");
    contentElement?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const showHideBottomsheet = () => {
    const scrollTop = contentRef.current.scrollTop;
    const scrollHeight = contentRef.current.scrollHeight;
    const clientHeight = contentRef.current.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
      setHideBottomsheet(false);
    } else {
      setHideBottomsheet(!hideBottomsheet);
    }
  };

  const isScrolledToBottom = (el: HTMLElement) =>
    el.scrollTop + el.clientHeight >= el.scrollHeight - 30;

  useEffect(() => {
    if (contentRef.current) {
      let oldScrollY = contentRef.current.scrollTop;
      const onScroll = () => {
        if (isScrolledToBottom(contentRef.current)) {
          setHideBottomsheet(false);
          setStyleBtn(true);
        } else {
          setStyleBtn(false);
        }

        if (contentRef.current.scrollTop >= 150) {
          if (disclaimerRef.current) {
            disclaimerRef.current.style.display = "flex";
            setTimeout(() => {
              disclaimerRef.current.style.opacity = "1";
              disclaimerRef.current.style.bottom = hideBottomsheet
                ? "-80px"
                : "0px";
            }, 50);
          }
          if (oldScrollY < contentRef.current.scrollTop) {
            if (disclaimerRef.current) {
              disclaimerRef.current.style.opacity = "1";
            }
          }
        } else {
          if (disclaimerRef.current) {
            disclaimerRef.current.style.opacity = "0";
            disclaimerRef.current.style.bottom = "-100px";
            setTimeout(() => {
              if (disclaimerRef.current.style.opacity === "0") {
                disclaimerRef.current.style.display = "none";
              }
            }, 300);
          }
        }

        oldScrollY = contentRef.current.scrollTop;
      };

      contentRef.current.onscroll = onScroll;

      return () => {
        if (contentRef.current) {
          contentRef.current.onscroll = null;
        }
      };
    }
  }, [contentRef.current, hideBottomsheet]);

  if (!researchByAlias) {
    return (
      <div className="research-page" style={{ paddingTop: "10%" }}>
        <div className="not-found-coming-soon">
          <p>{t("notFound")}</p>
        </div>
      </div>
    );
  }
  const locales: Record<string, Locale> = {
    en: enUS,
    ru: ru,
    am: hy,
  };
  const date = parse(researchByAlias.date, "yyyy.MM.dd", new Date());
  const formattedDate = isValid(date)
    ? format(date, "d MMMM yyyy", {
        locale: locales[i18n.language] || enUS,
      })
    : "Invalid date";

  return (
    <div className="research-page">
      <div
        className="research-page-content"
        ref={contentRef}
        onClick={showHideBottomsheet}
      >
        <div className="research-page-go-back">
          <img onClick={() => navigate(-1)} src={GoBack} alt="Go Back" />
        </div>

        <div
          className="research-page-content-top"
          style={{
            backgroundImage: `url(${sircapApi.baseUrl}/gallery/${researchByAlias.background_image})`,
          }}
        >
          <div className="research-page-content-top-categories">
            {researchByAlias.categories.map((category) => (
              <p
                key={category.id}
                //todo Add
                // onClick={(e) => {
                //   onCategoryClick(category.id);
                // }}
              >
                {getMLValue(category.name, i18n.language)}
              </p>
            ))}
          </div>

          <h1>{getMLValue(researchByAlias.title, i18n.language)}</h1>
          <div className="headerBottomContent">
            <span>{formattedDate}</span>
          </div>
        </div>

        <div className="authors">
          {members
            .filter((m) => researchByAlias.authors.includes(m.id))
            .map((member) => (
              <div key={member.id} className="authors-field">
                <img
                  src={
                    member.image
                      ? `${sircapApi.baseUrl}/gallery/${member.image}`
                      : Author
                  }
                  alt={getMLValue(member.name, i18n.language) || "Author"}
                />
                <div>
                  <p>{getMLValue(member.name, i18n.language)}</p>
                  <span>{getMLValue(member.role, i18n.language)}</span>
                </div>
              </div>
            ))}
        </div>

        <p
          style={{
            width: "90%",
            paddingBlock: "12px",
            textAlign: "left",
            color: "black",
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          {getMLValue(researchByAlias.subtitle, i18n.language)}
        </p>

        <div className="research-page-material">
          <PDF
            uri={`${sircapApi.baseUrl}/documents/${getMLValue(
              researchByAlias.pdf,
              i18n.language
            )}`}
          />
        </div>

        <div
          ref={disclaimerRef}
          className="disclaimer"
          style={{
            bottom: !hideBottomsheet ? 0 : "-80px",
            background: !hideBottomsheet ? "white" : "rgba(255, 255, 255, 0.9)",
            backdropFilter: !hideBottomsheet ? "none" : "blur(3px)",
            boxShadow: !hideBottomsheet
              ? "0px 0px 15px 0px #00000040"
              : "0 2px 6px -2px rgba(0, 0, 0, 0.4)",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="research-share">
            <p style={{ opacity: hideBottomsheet ? 0 : 1 }}>{t("share")}</p>

            <button
              className="page-up"
              onClick={pageUp}
              style={{
                bottom: !hideBottomsheet ? 0 : "-80px",
                background: !hideBottomsheet
                  ? "white"
                  : "rgba(255, 255, 255, 0.95)",
                backdropFilter: !hideBottomsheet ? "none" : "blur(5px)",
                boxShadow: !hideBottomsheet
                  ? "none"
                  : "0px 0px 8px 0px #00000040",
                animationName: styleBtn ? "pageUp" : "",
                animationDuration: styleBtn ? "1.5s" : "",
              }}
            >
              <img
                src={PageUp}
                alt="PageUp"
                style={{
                  animationName: styleBtn ? "pageUpImg" : "",
                  animationDuration: styleBtn ? "1.5s" : "",
                }}
              />
            </button>

            <div>
              {[
                {
                  image: Print,
                  link: `${sircapApi.baseUrl}/documents/${getMLValue(
                    researchByAlias.pdf,
                    i18n.language
                  )}`,
                  title: "Print",
                  download: true,
                },
                {
                  image: TG,
                  link: telegramShareLink,
                  title: "Send via telegram",
                },
                { image: Mail, link: emailShareLink, title: "Send via mail" },
                {
                  image: Link,
                  link: "",
                  title: "Copy link",
                  onClick: handleCopyLink,
                },
              ].map((item, index) => (
                <ShareBtn
                  key={index}
                  image={item.image}
                  link={item.link}
                  title={item.title}
                  download={item.download}
                  onClick={item.onClick}
                />
              ))}
            </div>
          </div>

          <a
            className="disclaimer-research-btn"
            href={`${sircapApi.baseUrl}/documents/${
              Disclaimer[i18n.language as keyof typeof Disclaimer] ||
              Disclaimer.en
            }`}
            download
            target="_blank"
          >
            {t("disclaimer2")}
          </a>
        </div>
      </div>
    </div>
  );
};
