import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import GoBack from "../../../assets/GoBackApp.svg";
import { PATH_LIST } from "shared/lib/react-router";
import { PersonalInfoEditCard } from "../components/personal-info-edit-card";
import { sessionModel } from "entities/session";
import { accountStore } from "entities/account";
import {
  startRegistration,
  browserSupportsWebAuthn,
} from "@simplewebauthn/browser";
import Modal from "widgets/auth/modal";
import "./style.scss";
import { sircapApi } from "shared/api/sircap";

export const SecurityPage: React.FC = () => {
  const { t } = useTranslation("app");
  const { t: o } = useTranslation("onboarding-auth");
  const { t: err } = useTranslation("errors");
  const { user, entity, biometric } = useStore(sessionModel.sessionStore);
  const [delModal, setDelModal] = useState(false);
  const [isBiometricsSupport, setIsBiometricsSupport] = useState(false);
  const [isBiometricsActive, setIsBiometricsActive] = useState(false);
  const { requestDataDeletion } = useStore(accountStore);
  const navigate = useNavigate();

  useEffect(() => {
    setIsBiometricsSupport(browserSupportsWebAuthn());
    setIsBiometricsActive(!!biometric);
  }, []);

  const handleNavigate = () => navigate(PATH_LIST.account);
  // const faceIDAndFingerprint = () => navigate(PATH_LIST.biometricAuth);
  const changePassword = () => navigate(PATH_LIST.changePassword);
  const handlePinCodeNavigation = () => {
    navigate(user?.hasPinCode ? PATH_LIST.changePIN : PATH_LIST.pinCode);
  };

  const requestToDelete = async (pinCode: string) => {
    setDelModal(false);
    try {
      await requestDataDeletion(err, pinCode);
    } catch {
      toast.error("Server Error");
    } finally {
      navigate(PATH_LIST.account);
    }
  };
  //todo bio auth
  // const handleBiometricsChange = async () => {
  //   if (!isBiometricsActive) {
  //     try {
  //       const response = await sircapApi.auth.biometricAuth({
  //         email: user!.email,
  //         entity,
  //       });

  //       if (!response.data || !response.data.data) {
  //         toast.error("Invalid biometric registration data");
  //         return;
  //       }

  //       const bioResp = await startRegistration(response.data.data);
  //       if (!bioResp) {
  //         toast.error("Biometric registration failed");
  //         return;
  //       }

  //       const resp = await sircapApi.auth.biometricAuthVerify({
  //         email: user!.email,
  //         entity,
  //         response: bioResp,
  //       });

  //       if (!resp.data || !resp.data.data) {
  //         toast.error("Biometric verification failed");
  //         return;
  //       }

  //       sessionModel.sessionStore.setState({ biometric: resp.data.data });
  //       setIsBiometricsActive(true);
  //     } catch (error) {
  //       toast.error("Failed to start biometric registration");
  //     }
  //   } else {
  //     // delete biometrics
  //     sessionModel.sessionStore.setState({ biometric: null });
  //     setIsBiometricsActive(false);
  //   }
  // };

  const securityOptions = [
    //todo bio auth
    // ...(isBiometricsSupport
    //   ? [
    //       {
    //         // name: t("biometricAuth"),
    //         name: t("Face ID / Fingerprint"),
    //         title: t("security").toUpperCase(),
    //         checkbox: true,
    //         checked: isBiometricsActive,
    //         onChange: () => handleBiometricsChange(),
    //       },
    //     ]
    //   : []),
    {
      onEdit: changePassword,
      name: t("changePassword"),
      title: o("password").toUpperCase(),
      //todo Open as bio auth ready
      // margin: true,
    },
    {
      onEdit: handlePinCodeNavigation,
      name: user?.hasPinCode ? t("changePinCode") : o("setPINCode"),
      notification: !user?.hasPinCode,
    },
    {
      onEdit: () => setDelModal(true),
      name: t("delMyAcc"),
      deletion: true,
      title: t("securityOther").toUpperCase(),
      margin: true,
    },
  ];

  return (
    <div className="personal-info">
      <div className="personal-info-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("security")}</p>
      </div>

      <div className="personal-info-main">
        {securityOptions.map((options, index) => (
          <PersonalInfoEditCard
            key={index}
            onEdit={options.onEdit}
            name={options.name}
            security
            notification={options.notification}
            deletion={options.deletion}
            label={{ title: options.title, margin: options.margin }}
            //todo bio auth
            // checkbox={{
            //   checkbox: options.checkbox,
            //   checked: options.checked,
            //   onChange: options.onChange,
            // }}
          />
        ))}
      </div>

      {delModal && (
        <Modal
          customModal="modalLogOut"
          onClick={requestToDelete}
          onHide={() => setDelModal(false)}
          title={t("confirmAccDel")}
          text={t("proceedWithAccDel")}
          btnText={t("confirm")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal
        />
      )}
    </div>
  );
};
