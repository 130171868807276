import React, { FC } from "react";
import "./style.scss";
import EditIcon from "../../../../assets/InfoEdit.svg";
import SecurityIcon from "../../../../assets/GoBackApp.svg";

type PropType = {
  name: string;
  info?: string;
  onEdit?: () => void;
  security?: boolean;
  deletion?: boolean;
  notification?: boolean;
  label?: {
    title?: string;
    margin?: boolean;
  };
  checkbox?: {
    checkbox?: boolean;
    checked?: boolean;
    onChange?: () => void;
  };
};

export const PersonalInfoEditCard: FC<PropType> = ({
  name,
  info,
  onEdit,
  security = false,
  deletion,
  notification,
  label,
  checkbox,
}) => {
  return (
    <>
      {label && (
        <label
          style={{ marginTop: label.margin ? "32px" : "0" }}
          className="personal-info-card-label"
        >
          {label.title}
        </label>
      )}
      <div
        onClick={(e) => {
          if (checkbox?.checkbox) {
            e.stopPropagation();
            checkbox.onChange?.();
          } else if (security && onEdit) {
            onEdit();
          }
        }}
        className={
          security
            ? "personal-info-card security-card"
            : "personal-info-card personal-info-card-media"
        }
      >
        <p
          className={notification ? "warning-icon" : ""}
          style={{
            color: deletion ? "red" : "rgba(43, 47, 56, 1)",
          }}
        >
          {name}
        </p>

        {checkbox && checkbox.checkbox ? (
          <div className="personal-info-card-checkbox">
            <label className="toggle">
              <input
                type="checkbox"
                className="toggle__input"
                checked={checkbox.checked}
                onChange={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <span className="toggle-track">
                <span className="toggle-indicator">
                  <span className="checkMark">
                    <svg
                      viewBox="0 0 24 24"
                      id="ghq-svg-check"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                    </svg>
                  </span>
                </span>
              </span>
            </label>
          </div>
        ) : !security ? (
          <div>
            <span>{info}</span>
            <img onClick={onEdit} src={EditIcon} alt="Edit-icon" />
          </div>
        ) : (
          <div>
            <img
              style={{ rotate: "180deg" }}
              src={SecurityIcon}
              alt="Change-icon"
            />
          </div>
        )}
      </div>
    </>
  );
};
