import { createStore, useStore } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { sircapApi } from "shared/api/sircap";
import { RegisterStep } from "entities/onboard";
import { EntityType } from "static/types";
import {
  DisableNotificationsDto,
  EnableNotificationsDto,
} from "shared/api/sircap/Api";
import { requestForToken } from "services/firebase";
import { accountStore } from "entities/account";

export enum UserVerifyStatus {
  None = "none",
  Pending = "pending",
  Success = "success",
  Rejected = "rejected",
}

export interface IUser {
  id: number;
  phone: string;
  email: string;
  personalAddress: string;
  regStep: RegisterStep;
  avatar: string;

  yurName: string;
  companyName: string;
  documents: any;
  additionalDocument: any;

  profileInfo: any;
  firstName: string;
  lastName: string;

  identificator: string;
  tariff: number;

  status: number;
  signContract: boolean;

  language: string;
  isEmailVerified: boolean;
  hasPinCode: boolean;
}

type User = null | IUser;

type SessionState = {
  user: User;
  accessToken: string;
  refreshToken: string;
  biometric: any | null;
  identificator: string;
  link: string;
  entity: EntityType;
  notificationToken: string;
  requestNotificationToken: () => void;
  enableNotifications: (dto: EnableNotificationsDto) => void;
  disableNotifications: (dto: DisableNotificationsDto) => void;

  setLink: (link: string) => void;
  addUser: (
    user: User,
    token: string,
    refresh_token: string,
    entityType: EntityType
  ) => void;
  updateUserAvatar: (avatar: string) => void;
  updateUser: (dto: any) => void;
  updateUserSignContract: (is: boolean) => void;
  logout: Function;

  signContractModal: boolean;
  setSignContractModal: (show: boolean) => void;

  inactiveAccountModal: boolean;
  setInactiveAccountModal: (show: boolean) => void;

  inactiveEmailModal: boolean;
  setInactiveEmailModal: (show: boolean) => void;

  inactivePinCodeModal: boolean;
  setInactivePinCodeModal: (show: boolean) => void;

  notVerifiedModal: boolean;
  setNotVerifiedModal: (show: boolean) => void;
};

export const sessionStore = createStore<SessionState>()(
  persist(
    devtools<SessionState>(
      (set: any, get: any) => ({
        user: null,
        accessToken: "",
        refreshToken: "",
        link: "",
        notificationToken: "",
        identificator: "",
        entity: EntityType.Individual,
        biometric: null,
        requestNotificationToken: async () => {
          try {
            const token = await requestForToken();
            if (token) {
              get().enableNotifications({
                notification_token: token,
                device_id: "",
              });
            } else {
              // check old token, and disable notifications for old token
            }
          } catch (error) {}
        },
        enableNotifications: async (dto: EnableNotificationsDto) => {
          try {
            const oldToken = get().notificationToken;
            if (oldToken !== dto.notification_token) {
              const resp = await sircapApi.nots.enable(dto);
              set({ notificationToken: dto.notification_token });
            }
          } catch (error) {}
        },
        disableNotifications: async (dto: DisableNotificationsDto) => {
          try {
            const resp = await sircapApi.nots.disable(dto);
            set({ notificationToken: "" });
          } catch (error) {}
        },

        setLink: (link: string) => {
          set({ link });
        },
        addUser: (
          user: User,
          token: string,
          refresh_token: string,
          entity: EntityType
        ) => {
          sircapApi.setSecurityData(token);
          set({
            user,
            accessToken: token,
            refreshToken: refresh_token,
            entity,
            identificator: user?.identificator || "",
          });
        },
        updateUserAvatar: (avatar: string) => {
          set({
            user: {
              ...get().user,
              avatar: avatar ? avatar + "?t=" + new Date().getTime() : "",
            },
          });
        },
        updateUser: (dto: any) => {
          set({
            // @ts-ignore
            user: {
              ...get().user,
              ...dto,
            },
          });
        },
        updateUserSignContract: (is: boolean) => {
          set({
            user: {
              ...get().user,
              signContract: is,
            },
          });
        },
        logout: () => {
          localStorage.removeItem("login");
          set({
            user: null,
            accessToken: "",
            refreshToken: "",
            link: "",
            entity: EntityType.Individual,
          });
          sircapApi.setSecurityData(null);
        },

        signContractModal: false,
        setSignContractModal: (show: boolean) => {
          set({ signContractModal: show });
        },

        inactiveAccountModal: false,
        setInactiveAccountModal: (show: boolean) => {
          set({ inactiveAccountModal: show });
        },

        inactiveEmailModal: false,
        setInactiveEmailModal: (show: boolean) => {
          set({ inactiveEmailModal: show });
        },

        inactivePinCodeModal: false,
        setInactivePinCodeModal: (show: boolean) => {
          set({ inactivePinCodeModal: show });
        },

        notVerifiedModal: false,
        setNotVerifiedModal: (show: boolean) => {
          set({ notVerifiedModal: show });
        },
      }),
      {
        name: "Session Store",
      }
    ),
    {
      name: "session",
      version: 1,
      merge: (persistedState, currState) => {
        const {
          user,
          accessToken,
          refreshToken,
          entity,
          notificationToken,
          biometric,
          identificator,
        } = persistedState as Partial<SessionState>;
        if (accessToken) {
          sircapApi.setSecurityData(accessToken);
        }

        return {
          ...currState,
          // user: user || null,
          accessToken: accessToken || "",
          refreshToken: refreshToken || "",
          notificationToken: notificationToken || "",
          biometric: biometric || null,
          identificator: identificator || "",
        };
      },
    }
  )
);

export const useAuth = () =>
  useStore(sessionStore, (state) => !!state.accessToken);

export const useCurrentUser = () =>
  useStore(sessionStore, (state) => state.user);

export const useUserName = (takeDirectorNameForEntity = false) =>
  useStore(sessionStore, (state) =>
    state.entity === EntityType.Individual
      ? `${state.user?.lastName || ""} ${state.user?.firstName || ""}`
      : takeDirectorNameForEntity
      ? accountStore.getState().kyb?.yur_name || ""
      : state.user?.companyName
  );

export const useUserContract = () =>
  useStore(sessionStore, (state) => state.user?.identificator || "");

export const useUserIsActive = () =>
  useStore(sessionStore, (state) => {
    if (state.user && state.user.status) {
      return state.user.status === 1;
    } else {
      return false;
    }
  });
