import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import i18n from "app/libs/i18n";
import { getMLValue, MultiLangField } from "helpers/functions";
import { sircapApi } from "shared/api/sircap";
import { AssetType } from "static/types";
import DropdownIcon from "../../../../assets/GoBackApp.svg";
import WarningAcc from "../../../../assets/WarningAcc.svg";
import CheckIcon from "../../../../assets/icon-check.svg";
import "./style.scss";

type PropsType = {
  type: "asset" | "select" | "number" | "string";
  label?: MultiLangField;
  placeholder?: MultiLangField;
  selectOptions?: any[];
  assetTypes?: AssetType[];
  isOptional?: boolean;
  onChange: (e: any, valid?: boolean) => void;
};

export const AssignmentsFormField: FC<PropsType> = ({
  type,
  label,
  placeholder,
  selectOptions,
  assetTypes,
  isOptional,
  onChange,
}) => {
  const { t } = useTranslation("app");
  const [selected, setSelected] = useState(selectOptions?.[0]?.value || "");
  const [inputValue, setInputValue] = useState(selected);
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [selectedType, setSelectedType] = useState<AssetType>(AssetType.Stock);
  const [symbols, setSymbols] = useState<
    { id: number; symbol: string; exchange: string }[]
  >([]);
  const [filteredSymbols, setFilteredSymbols] = useState(symbols);

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   setInputValue(Number(value).toFixed(4));
  //   onChange(+e.target.value);

  //   if (value === "") {
  //     setInputValue("");
  //     onChange("");
  //     return;
  //   }
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    setInputValue(+value.toFixed(6));
    onChange(value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toUpperCase().trim());
  };

  useEffect(() => {
    if (!assetTypes) return;

    const fetchAssets = async () => {
      try {
        const resp = await sircapApi.market.assetsAPI(selectedType)({
          params: { mode: "simple" },
        });
        setSymbols(resp?.data?.data || []);
        setFilteredSymbols(resp?.data?.data || []);
      } catch {
        setSymbols([]);
        setFilteredSymbols([]);
      }
    };

    fetchAssets();
    setSearchTerm("");
  }, [selectedType, assetTypes]);

  useEffect(() => {
    if (!debouncedSearchTerm.trim()) {
      setFilteredSymbols(symbols);
      return;
    }
    setFilteredSymbols(
      symbols.filter((item) =>
        item.symbol.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      )
    );
  }, [debouncedSearchTerm, symbols]);

  useEffect(() => {
    const symbolExists = symbols.some(
      (item) => item.symbol.toLowerCase() === searchTerm.toLowerCase()
    );
    onChange(searchTerm, symbolExists);
  }, [searchTerm, symbols]);

  // useEffect(() => {
  //   console.log("Changed");
  // }, [onChange]);

  useEffect(() => {
    if (selected !== "") {
      onChange(selected);
    }
  }, [selected]);

  return (
    <div className="assignments-form-field">
      {label && (
        <label htmlFor={getMLValue(label, "en")}>
          {getMLValue(label, i18n.language)}
          {isOptional && (
            <p>
              {t("optionalField")}
              <img src={WarningAcc} alt="WarningAcc" />
            </p>
          )}
        </label>
      )}

      {type == "asset" && assetTypes ? (
        <div className="assignments-form-dropdown-group">
          <div className="assignments-form-dropdown-item">
            <p>{AssetType[selectedType]}</p>
            <img
              className="assignments-dropdown-icon"
              src={DropdownIcon}
              alt="DropdownIcon"
            />
            <ul>
              {assetTypes.map((item, index) => (
                <li
                  key={index}
                  onClick={() => setSelectedType(item)}
                  style={{
                    paddingRight: assetTypes.length > 4 ? "14.7px" : "18.7px",
                  }}
                >
                  {AssetType[item]}
                  {selectedType == item && (
                    <img src={CheckIcon} alt="CheckIcon" />
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div
            className="assignments-form-dropdown-input-item"
            style={{ borderRadius: isActive ? "6px 6px 0 0" : "6px" }}
          >
            <input
              id={label && getMLValue(label, "en")}
              type="text"
              placeholder={`${t("search")}...`}
              value={searchTerm}
              onChange={handleSearch}
              onFocus={() => {
                setIsActive(true);
                setFilteredSymbols(symbols);
              }}
              onBlur={() => setIsActive(false)}
              autoComplete="off"
            />

            <ul
              style={{
                visibility: isActive ? "visible" : "hidden",
                borderRadius: isActive ? "0 0 6px 6px" : "6px",
              }}
            >
              {filteredSymbols.map((symbol) => (
                <li
                  key={symbol.id}
                  onClick={() => {
                    setSearchTerm(symbol.symbol);
                    setIsActive(false);
                  }}
                  style={{
                    paddingRight: assetTypes?.length > 4 ? "14.7px" : "18.7px",
                  }}
                >
                  {symbol.symbol}

                  {(selectedType == AssetType.Stock ||
                    selectedType == AssetType.ETF ||
                    selectedType == AssetType.Fond) && (
                    <span>{symbol.exchange}</span>
                  )}
                </li>
              ))}
            </ul>

            {searchTerm.trim() !== "" &&
              !symbols.some(
                (item) => item.symbol.toLowerCase() === searchTerm.toLowerCase()
              ) && (
                <span className="assignments-form-asset-validation">
                  {t("symbolNotFound")}
                </span>
              )}
          </div>
        </div>
      ) : type == "select" && selectOptions ? (
        <div className="assignments-form-dropdown">
          <p>
            {getMLValue(
              selectOptions.find((v) => v.value === selected).key,
              i18n.language
            )}
          </p>
          <img
            className="assignments-dropdown-icon"
            src={DropdownIcon}
            alt="DropdownIcon"
          />
          <ul
            style={{ overflow: selectOptions.length > 4 ? "scroll" : "hidden" }}
          >
            {selectOptions.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelected(item.value);
                  onChange(item.value);
                }}
                style={{
                  paddingRight: selectOptions.length > 4 ? "14px" : "17.7px",
                }}
              >
                {getMLValue(item.key, i18n.language)}

                {selected == item.value && (
                  <img src={CheckIcon} alt="CheckIcon" />
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : type == "number" ? (
        <input
          id={label && getMLValue(label, "en")}
          type="number"
          placeholder={placeholder && getMLValue(placeholder, i18n.language)}
          autoComplete="off"
          onChange={handleInputChange}
          value={inputValue}
          min={0}
          step={1}
        />
      ) : (
        <input
          id={label && getMLValue(label, "en")}
          type="text"
          autoComplete="off"
          placeholder={placeholder && getMLValue(placeholder, i18n.language)}
          onChange={(e) => {
            const trimmedValue = e.target.value.trimStart();
            if (trimmedValue === "" && e.target.value !== "") {
              return;
            }
            setInputValue(trimmedValue);
            onChange(trimmedValue);
          }}
          value={inputValue}
        />
      )}
    </div>
  );
};
