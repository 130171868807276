import React, { FC, useState } from "react";
import "./style.scss";
import CheckIcon from "../../../../assets/icon-check.svg";
import { CURRENCY_FLAGS } from "static/currencies";
import { useTranslation } from "react-i18next";

interface IInfoSource {
  id: number;
  name: string;
  image: string;
}

type PropTypeRow = {
  currency?: string;
  bank?: IInfoSource;
  customImage?: string;
  onCurrencyClick: () => void;
  isChecked: boolean;
};

export const CurrencyRow: FC<PropTypeRow> = ({
  currency,
  bank,
  onCurrencyClick,
  isChecked,
  customImage,
}) => {
  return (
    <div onClick={onCurrencyClick} className="currency-row">
      <div>
        <img
          src={customImage || (bank ? bank.image : CURRENCY_FLAGS[currency!])}
          alt={`${bank ? bank : currency}-icon`}
        />
        <p>{bank ? bank.name : currency}</p>
      </div>
      {isChecked && <img src={CheckIcon} alt="CheckIcon" />}
    </div>
  );
};

type PropType = {
  onClose: () => void;
  onCurrencyChange: (currency: any) => void;
  selectedCurrency: string;
  selectedBank?: any;
  type: "bank" | "currency" | "custom";
  currencies?: string[];
  banks?: IInfoSource[];
  customList?: Array<{ id: string; name: string; image: string }>;
  customText?: string;
  selectedCustom?: any;
};

export const CurrencyModal: FC<PropType> = ({
  onClose,
  onCurrencyChange,
  selectedCurrency,
  currencies,
  selectedBank,
  banks,
  type,
  customList,
  customText,
  selectedCustom,
}) => {
  const [selectedItem, setSelectedItem] = useState<any>(selectedCurrency);
  const [selectedBankItem, setSelectedBankItem] = useState<any>(selectedBank);
  const { t } = useTranslation("app");

  const handleCurrencyChange = (currency: any) => {
    setSelectedItem(currency);
    setSelectedBankItem(selectedBank);
    onCurrencyChange(currency);
    onClose();
  };

  return (
    <>
      <div onClick={onClose} className="outlet-currency-modal"></div>

      <div className="currency-modal">
        <div className="currency-modal-header">
          <p>
            {customText || (banks ? t("recipientBank") : t("selectCurrency"))}
          </p>
        </div>

        {type === "custom" ? (
          <>
            {customList!.map((item) => (
              <CurrencyRow
                key={item.id}
                isChecked={selectedCustom === item.id}
                currency={item.name}
                onCurrencyClick={() => handleCurrencyChange(item.id)}
                customImage={item.image}
              />
            ))}
          </>
        ) : type === "bank" ? (
          banks!.map((item) => (
            <CurrencyRow
              key={item.id}
              isChecked={selectedBankItem === item.id}
              currency={item.name}
              onCurrencyClick={() => handleCurrencyChange(item.id)}
              bank={item}
            />
          ))
        ) : (
          currencies!.map((item) => (
            <CurrencyRow
              key={item}
              isChecked={selectedItem === item}
              currency={item}
              onCurrencyClick={() => handleCurrencyChange(item)}
              bank={undefined}
            />
          ))
        )}
      </div>
    </>
  );
};
